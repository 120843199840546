<section class="container">

    <h1 class="leading-none mb-9 pt-6">Сигналы</h1>

    <div class="signals">
        <div class="signals__left">
            <div class="signals__filter">
                <app-assets-filter />
            </div>
            <div class="signals__examples">
                <ul>
                    <li class="signals__examples-item">
                        <div class="signals__examples-indicator bg-greenPrimary"></div> – фьючерс перекуплен участниками рынка
                    </li>
                    <li class="signals__examples-item">
                        <div class="signals__examples-indicator bg-error"></div> – фьючерс перепродан участниками рынка
                    </li>
                    <li class="signals__examples-item">
                        <div class="signals__examples-indicator bg-green4"></div> – нейтральное положение участников рынка
                    </li>
                    <li class="signals__examples-item">
                        <div class="signals__examples-indicator bg-gray4"></div> – недоступный
                    </li>
                </ul>
                <i class="block mt-4 text-body-3">Не является ИИР</i>
            </div>
        </div>

        <div class="signals__right">
            <div class="signals__loader" [ngClass]="{
                'signals__loader--hidden': !assetsService.isLoading
            }">
                <div class="signals__loader-content">
                    <app-loader-dots label="Загрузка активов" [showLabel]="true" />
                </div>
            </div>
            <div class="max-w-full overflow-hidden transition-all" [ngClass]="{
                'opacity-0': assetsService.isLoading,
            }">
                <p *ngIf="!signalFilterService.filterAssets(assetsService.avalibleAssets).length"
                    class="signals__notfound">
                    Доступных активов не найдено
                </p>
                <app-assets-table *ngIf="signalFilterService.filterAssets(assetsService.avalibleAssets).length"
                    [assets]="signalFilterService.filterAssets(assetsService.avalibleAssets)" />
            </div>
        </div>
    </div>

    <div *ngIf="assetsService.unavalibleAssets.length" class="signals" [ngClass]="{
        'opacity-0': assetsService.isLoading,
    }">
        <div class="signals__left">
            <div class="signals__filter">
                <h4>Недоступные</h4>
                <button class="my-4" (click)="subscriptionService.openTariffPopup()">Разблокировать</button>
            </div>
        </div>
        <div class="signals__right">
            <app-unavalible-assets-table [assets]="assetsService.unavalibleAssets" />
        </div>
    </div>

    <section class="mt-[88px] relative tariffs">
        <div class="light-ellipse">
            <div class="light-ellipse__circle top-0 left-0"></div>
        </div>

        <h3>Тарифные планы</h3>
        <p class="mt-6 text-subheader-2">Выберите свой путь к успеху с нашими тарифными планами!</p>
        <div class="mt-8 mb-12">
            <app-tariff-list />
        </div>
    </section>

</section>