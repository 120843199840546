import { Injectable } from '@angular/core';
import { IAsset } from '../assets/models/assets';
import { EChartTypes } from './models/chart-types';
import { EPayerTypes, PayerTypessAliases } from './models/payer-types';
import { dateFilters, IDateFilter } from './models/date-filter';
import { AssetsRepository } from '../assets/repository/assets';
import { NotifierService } from 'angular-notifier';
import { AssetsService } from '../assets/assets.service';
import { ChartRepository } from './repository/chart';
import { getStringedDate, getTimestamp } from 'src/app/shared/utils/stringed-date';
import { EChartsOption } from 'echarts';
import { EPositionTypes, PositionTypesAliases } from './models/position-types';
import { IChartData } from './models/chart';
import { getSoloChartOptions } from './chart/options/solo-chart-options';
import { IPositionReport, IPriceReport, IRsiReport } from './models/reports';
import { individualPositionColors, legalPositionColors } from './chart/chart-colors';
import { getDuoChartOptions } from './chart/options/duo-chart-options';
import { getRsiChartOptions } from './chart/options/rsi-chart-options';
import { getPriceChartOptions } from './chart/options/price-chart-options';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public isLoading: boolean = false;
  public isFullscreen: boolean = false;

  public currentAsset: IAsset | null = null;

  public currentChartType: EChartTypes = EChartTypes.INDIVIDUAL;
  public currentPayerType: EPayerTypes = EPayerTypes.INDIVIDUAL;
  public currentPositionType: EPositionTypes = EPositionTypes.SHORT;

  public dateFilter: IDateFilter = dateFilters[2];

  public options: EChartsOption = {};

  public get isChartPage(): boolean {
    return location.pathname == '/chart';
  }

  constructor(
    private readonly _assetsRepository: AssetsRepository,
    private readonly _chartRepository: ChartRepository,
    private readonly _notifierService: NotifierService,
    private readonly _assetServise: AssetsService
  ) {}

  public setCurrentAsset(asset: IAsset | null): void {
    if (asset && this.currentAsset?.id == asset.id) return;
    this.currentAsset = asset;
    this.fetchReports();
  }

  public setCurrentChartType(type: EChartTypes): void {
    if (this.currentChartType == type) return;
    this.currentChartType = type;
    this.fetchReports();
  }

  public setDateFilter(filter: IDateFilter): void {
    this.dateFilter = filter;
    this.fetchReports();
  }

  public setCurrentPayerType(type: EPayerTypes): void {
    this.currentPayerType = type;
    this.fetchReports();
  }

  public setCurrentPositionType(type: EPositionTypes): void {
    this.currentPositionType = type;
    this.updateChart();
  }

  public fetchAssetByIsin(isin: string) {
    this.isLoading = true;
    this._assetsRepository.getAssets('', null, isin).subscribe((response) => {
      if (response.success) {
        const asset = response.data.avalible[0] || response.data.unavalible[0];
        if (asset) {
          this.setCurrentAsset(asset);
          this._assetServise.setType(asset.type);
        } else {
          this._notifierService.notify('error', 'Актив не найден');
        }
      } else if (response.error) {
        this._notifierService.notify('error', 'Не удалось загрузить данные актива');
      }
      this.isLoading = false;
    });
  }

  // FIXME рефакторинг все что ниже!!!
  // FIXME рефакторинг все что ниже!!!
  // FIXME рефакторинг все что ниже!!!
  public reports: (IPositionReport | IPriceReport | IRsiReport)[];

  public fetchReports(): void {
    if (!this.currentAsset?.available) return;

    this.isLoading = true;
    this.reports = [];

    const dateFrom: string = getStringedDate(this.dateFilter.range.from);
    const dateTo: string = getStringedDate(this.dateFilter.range.to);
    const dateInterval: number = this.dateFilter.daysInterval;
    const izFiz: boolean = this.currentPayerType == EPayerTypes.INDIVIDUAL;

    let fetchPosition: Observable<any>;

    switch (this.currentChartType) {
      case EChartTypes.INDIVIDUAL:
        fetchPosition = this._chartRepository.getIndividualPosition(
          this.currentAsset.id,
          dateFrom,
          dateTo,
          dateInterval,
          izFiz
        );
        break;
      case EChartTypes.LEGAL:
        fetchPosition = this._chartRepository.getLegalPosition(
          this.currentAsset.id,
          dateFrom,
          dateTo,
          dateInterval,
          izFiz
        );
        break;
      case EChartTypes.RSI:
        fetchPosition = this._chartRepository.getRsi(
          this.currentAsset.id,
          dateFrom,
          dateTo,
          dateInterval,
          izFiz
        );
        break;
      case EChartTypes.FUTURES:
        fetchPosition = this._chartRepository.getPrice(
          this.currentAsset.id,
          dateFrom,
          dateTo,
          dateInterval,
          izFiz
        );
        break;
    }

    fetchPosition.subscribe((response) => {
      this.reports = response.data;
      this.isLoading = false;
      this.updateChart();
    });
  }

  public updateChart(): void {
    switch (this.currentChartType) {
      case EChartTypes.INDIVIDUAL:
        this.updateIndividualPositionsOptions();
        break;
      case EChartTypes.LEGAL:
        this.updateLegalPositionsOptions();
        break;
      case EChartTypes.RSI:
        this.currentPositionType = EPositionTypes.SHORT_LONG;
        this.updateRsiOptions();
        break;
      case EChartTypes.FUTURES:
        // this.currentPayerType = EPayerTypes.LEGAL;
        this.currentPositionType = EPositionTypes.SHORT_LONG;
        this.updatePriceOptions();
        break;
    }
  }

  private updateIndividualPositionsOptions(): void {
    const reports = this.reports as IPositionReport[];

    const timestamps: number[] = [];
    const shortData: number[] = [];
    const longData: number[] = [];
    const pureData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const { short, long, pure } = report.value;

      timestamps.push(timestamp);
      shortData.push(short);
      longData.push(long);
      pureData.push(pure);
    }

    const payerAlias: string = PayerTypessAliases[this.currentPayerType];

    const shortChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.SHORT]})`,
      color: individualPositionColors[EPositionTypes.SHORT],
      timestamps: timestamps,
      data: shortData,
    };

    const longChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.LONG]})`,
      color: individualPositionColors[EPositionTypes.LONG],
      timestamps: timestamps,
      data: longData,
    };

    const pureChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.PURE]})`,
      color: individualPositionColors[EPositionTypes.PURE],
      timestamps: timestamps,
      data: pureData,
    };

    switch (this.currentPositionType) {
      case EPositionTypes.SHORT:
        this.options = getSoloChartOptions(shortChartData);
        break;
      case EPositionTypes.LONG:
        this.options = getSoloChartOptions(longChartData);
        break;
      case EPositionTypes.PURE:
        this.options = getSoloChartOptions(pureChartData);
        break;
      case EPositionTypes.SHORT_LONG:
        this.options = getDuoChartOptions([shortChartData, longChartData]);
        break;
    }
  }

  private updateLegalPositionsOptions(): void {
    const reports = this.reports as IPositionReport[];

    const timestamps: number[] = [];
    const shortData: number[] = [];
    const longData: number[] = [];
    const pureData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const { short, long, pure } = report.value;

      timestamps.push(timestamp);
      shortData.push(short);
      longData.push(long);
      pureData.push(pure);
    }

    const payerAlias: string = PayerTypessAliases[this.currentPayerType];

    const shortChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.SHORT]})`,
      color: legalPositionColors[EPositionTypes.SHORT],
      timestamps: timestamps,
      data: shortData,
    };

    const longChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.LONG]})`,
      color: legalPositionColors[EPositionTypes.LONG],
      timestamps: timestamps,
      data: longData,
    };

    const pureChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.PURE]})`,
      color: legalPositionColors[EPositionTypes.PURE],
      timestamps: timestamps,
      data: pureData,
    };

    switch (this.currentPositionType) {
      case EPositionTypes.SHORT:
        this.options = getSoloChartOptions(shortChartData);
        break;
      case EPositionTypes.LONG:
        this.options = getSoloChartOptions(longChartData);
        break;
      case EPositionTypes.PURE:
        this.options = getSoloChartOptions(pureChartData);
        break;
      case EPositionTypes.SHORT_LONG:
        this.options = getDuoChartOptions([shortChartData, longChartData]);
        break;
    }
  }

  private updateRsiOptions(): void {
    const reports = this.reports as IRsiReport[];
    const timestamps: number[] = [];
    const data: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const value: number = report.value;
      timestamps.push(timestamp);
      data.push(value);
    }

    const payerAlias: string = PayerTypessAliases[this.currentPayerType];
    const chartData: IChartData<number> = {
      name: `Сигнал (${payerAlias})`,
      timestamps: timestamps,
      data: data,
    };

    let payerField: 'yur' | 'fiz';
    switch (this.currentPayerType) {
      case EPayerTypes.INDIVIDUAL:
        payerField = 'fiz';
        break;
      case EPayerTypes.LEGAL:
        payerField = 'yur';
        break;
    }

    const overbought = this.currentAsset?.rsi?.[payerField]?.overbought || 70;
    const oversold = this.currentAsset?.rsi?.[payerField]?.oversold || 30;

    this.options = getRsiChartOptions(overbought, oversold, chartData);
  }

  private updatePriceOptions(): void {
    const reports = this.reports as IPriceReport[];

    const timestamps: number[] = [];
    const data: number[] = [];
    const rsiData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const value: number = report.value;
      const rsi: number = report.rsi;

      timestamps.push(timestamp);
      data.push(value);
      rsiData.push(rsi);
    }

    const chartData: IChartData = {
      name: 'Фьючерс',
      timestamps: timestamps,
      data: data,
    };

    let payerField: 'yur' | 'fiz';
    switch (this.currentPayerType) {
      case EPayerTypes.INDIVIDUAL:
        payerField = 'fiz';
        break;
      case EPayerTypes.LEGAL:
        payerField = 'yur';
        break;
    }

    const overbought = this.currentAsset?.rsi?.[payerField]?.overbought || 70;
    const oversold = this.currentAsset?.rsi?.[payerField]?.oversold || 30;

    this.options = getPriceChartOptions(chartData, overbought, oversold, rsiData);
  }
}
