<div #list class="list">
    <div *ngFor="let asset of (showMore ? assets : assets.slice(0, SHOWN_LIMIT))" class="list__item"
        [title]="'Перейти на график: ' + asset.name" [ngClass]="{
                    'list__item--success': asset.price && asset.price.daily_changes_perc && asset.price.daily_changes_perc > 0
                    }">
        <a class="list__item-link" routerLink="/chart" [queryParams]="{isin: asset.isin}">
            <span>Смотреть график</span>
        </a>
        <span class="list__item-title">{{ asset.name }}</span>
        <div *ngIf="asset.price && asset.price.value" class="flex-grow flex items-center small:relative max-small:mt-2">
            <div class="small:flex-grow mr-2">
                <div class="list__item-icon" *ngIf="asset.price.daily_changes_value || asset.price.daily_changes_perc">
                    <icon-arrow-down *ngIf="(asset.price.daily_changes_value && asset.price.daily_changes_value < 0) ||
                         (asset.price.daily_changes_perc && asset.price.daily_changes_perc < 0)" />
                    <icon-arrow-up *ngIf="(asset.price.daily_changes_value && asset.price.daily_changes_value > 0) ||
                        (asset.price.daily_changes_perc && asset.price.daily_changes_perc > 0)" />
                </div>
            </div>
            <span class="list__item-value">
                {{ asset.price.value }}
            </span>
            <span class="list__item-change" *ngIf="asset.price.daily_changes_value || asset.price.daily_changes_perc">
                <span *ngIf="asset.price.daily_changes_value">
                    {{ asset.price.daily_changes_value | valueSign }}
                </span>
                <span *ngIf="asset.price.daily_changes_perc">
                    ({{ asset.price.daily_changes_perc | valueSign }}%)
                </span>
            </span>
        </div>
        <div class="list__item-favorite">
            <app-favorite-button [assetId]="asset.id" />
        </div>
    </div>
</div>
<button *ngIf="assets.length > SHOWN_LIMIT" (click)="showMore=!showMore" class="list__more"
    [title]="showMore ?'Свернуть' : 'Смотреть все'">
    {{ showMore ? 'Свернуть' : 'Смотреть все' }}
</button>