export function getStringedDate(date: Date): string {
  let stringedDate =
    +date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    date.getDate().toString().padStart(2, '0');
  return stringedDate;
}

export function getTimestamp(dateString: string): number {
  const date = new Date(dateString);
  return date.getTime();
}
