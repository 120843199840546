<div class="mb-6 flex justify-between items-center max-small:flex-col"
    [ngClass]="{ 'max-laptop:hidden': router.url.includes('/chart') }">
    <div class="max-laptop:hidden">
        <app-tabs-select [options]="assetsTypeOptions"
            [current]="getCurrentOption(assetsTypeOptions, assetsService.type)"
            (valueChanged)="setAssetsTypeHandler($event.value)" />
    </div>
    <button *ngIf="!router.url.includes('/chart')"
        class="button--white small:w-[220px] laptop:ml-6 max-small:mt-5 w-full" routerLink="/assets">Все активы</button>
</div>
<div class="relative">
    <div #skeleton [@skeletonAnimation]="chartService.isFullscreen ? 'wide' : 'small'"
        class="bg-gray3 rounded-lg top-0 right-0 bottom-0 left-0 z-[-1] absolute opacity-50 flex items-center justify-center">
        <span>Полноэкранный режим</span>
    </div>

    <div #analytics class="analytics" [@fullscreenAnimation]="chartService.isFullscreen ? 'wide' : 'small'">

        <div class="analytics__filter">

            <app-tabs-select-small class="flex-grow max-laptop:hidden" [options]="chartTypeOptions"
                [current]="getCurrentOption(chartTypeOptions, chartService.currentChartType)"
                (valueChanged)="setChartTypeHandler($event.value)" />

            <app-select class="w-full laptop:hidden max-laptop:mb-4" [options]="chartTypeOptions"
                [current]="getCurrentOption(chartTypeOptions, chartService.currentChartType)"
                (valueChanged)="setChartTypeHandler($event.value)" />

            <div class="laptop:ml-4 flex flex-wrap justify-end max-phone2:flex-col laptop:gap-x-6 max-laptop:gap-4">
                <app-select class="laptop:min-w-[190px] flex-grow max-laptop:basis-0" *ngIf="isShowPositions()"
                    [options]="positionTypeOptions"
                    [current]="getCurrentOption(positionTypeOptions, chartService.currentPositionType)"
                    (valueChanged)="chartService.setCurrentPositionType($event.value)" />

                <app-select class="laptop:min-w-[190px] flex-grow max-laptop:basis-0" *ngIf="isShowPayer()"
                    [options]="payerTypeOptions"
                    [current]="getCurrentOption(payerTypeOptions, chartService.currentPayerType)"
                    (valueChanged)="chartService.setCurrentPayerType($event.value)" />
            </div>

        </div>

        <div class="mb-4 laptop:flex justify-between items-center max-laptop:items-stretch">
            <app-asset-picker class="small:max-w-[80%]" [avalibleAssets]="assetsService.avalibleAssets"
                [unavalibleAssets]="assetsService.unavalibleAssets" />
            <label (click)="toggleFullscreen()" class="analytics__fullscreen">
                <span class="analytics__fullscreen-label">
                    {{ chartService.isFullscreen ? "Свернуть" : "На весь экран" }}
                </span>
                <span class="analytics__fullscreen-icon">
                    <icon-fullscreen *ngIf="!chartService.isFullscreen" />
                    <icon-smallscreen *ngIf="chartService.isFullscreen" />
                </span>
            </label>
        </div>

        <div class="mb-8">
            <app-chart />
        </div>

        <div class="analytics__periods">
            <app-chart-period />
        </div>

        <p class="analytics__description"
            *ngIf="chartService.currentChartType == EChartTypes.INDIVIDUAL || chartService.currentChartType == EChartTypes.LEGAL">
            <b>График шорт позиций</b> – отображает объём шорт (продажных) позиций на срочном рынке.
            <br>
            <b>График лонг позиций</b> – показывает объём лонг (покупных) позиций на срочном рынке.
            <br>
            <b>График чистых позиций</b> – вычисляет разницу между лонг и шорт позициями.
        </p>

        <p class="analytics__description" *ngIf="chartService.currentChartType == EChartTypes.RSI">
            Показывает уровень перекупленности или перепроданности участников рынка за выбранный временной диапазон. Сигналы генерируются на основе чистых позиций количества договоров, когда RSI пересекает верхнюю или нижнюю границу, установленную на основе стандартного отклонения от среднего значения RSI за этот период. 
        </p>

        <p class="analytics__description" *ngIf="chartService.currentChartType == EChartTypes.FUTURES">
            Итоги торгов по фьючерсному контракту с ближайший датой исполнения за период. Демонстрирует текущую стоимость фьючерса, изменяя цвет на красный или зелёный в зависимости от пересечения границ RSI в открытом интересе участников срочного рынка.
        </p>
    </div>
</div>