import { isMobileScreen } from 'src/app/shared/utils/mobile';
import { EDynamicColors } from './chart-colors';
import { getStandardDeviation, getSumOfArray } from 'src/app/shared/utils/math';

const isSmallScreen: boolean = isMobileScreen();

// функцию добавляет Null значения для визуальных отступов на графике
export function addNullsToData<T>(data: T[]): T[] {
  const startSize = isSmallScreen ? 7 : 15;
  const endSize = isSmallScreen ? 100 : 100;

  let startNullCount: number = Math.round(data.length / startSize);
  let endNullCount: number = Math.round(data.length / endSize);

  //   создает null массивы и добавляет их в начало и конец основного массива
  const startArray =
    startNullCount >= 1 ? new Array(startNullCount).fill(null) : [];
  const endArray = endNullCount >= 1 ? new Array(endNullCount).fill(null) : [];
  data = [...startArray, ...data, ...endArray];

  return data;
}

// функцию генерирует массив объектов, который
// нужен для отражения rsi на графике фьючерса
export function generateVisualMapPieces(
  overbought: number,
  oversold: number,
  data: number[]
): object {
  let overboughtTreshold = overbought;
  let oversoldTreshold = oversold;

  // если есть данные, рассчитываем пороговые значения
  //   if (data.filter((v) => v).length) {
  //     let rsiMean = getSumOfArray(data) / data.length;
  //     let rsiStd = getStandardDeviation(data);
  //     overboughtTreshold = Math.round(rsiMean + rsiStd);
  //     oversoldTreshold = Math.round(rsiMean - rsiStd);
  //   }

  let pieces: { min?: number; max?: number; color?: string }[] = [];
  let currentPiece: { min?: number; max?: number; color?: string } = {};

  for (let i = 0; i < data.length; i++) {
    let rsiValue = data[i];

    let color;
    // определяет цвет линии в зависимости от показателя rsi и пороговых значений
    if (rsiValue > 0 && rsiValue <= oversoldTreshold) {
      color = EDynamicColors.FAIL;
    } else if (rsiValue >= overboughtTreshold && rsiValue <= 100) {
      color = EDynamicColors.SUCCESS;
    } else {
      color = EDynamicColors.NEUTRAL;
    }

    if (currentPiece.color === color) {
      currentPiece.max = i;
    } else {
      if (currentPiece.color) {
        pieces.push(currentPiece);
      }
      currentPiece = {
        min: i == 0 ? i : i - 1,
        max: i,
        color: color,
      };
    }
  }

  if (currentPiece.color) {
    pieces.push(currentPiece);
  }

  return pieces;
}
