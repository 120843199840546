import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('sliding', [
      state('shown', style({ left: '0' })),
      state('hidden', style({ left: '100%' })),
      transition('hidden => shown', animate('1000ms ease')),
    ]),
  ],
})
export class HeaderComponent {
  public svgPageNamePath: string | undefined;
  public svgState: 'shown' | 'hidden' = 'hidden';

  constructor(private readonly router: Router) {
    let previousUrl: string | undefined;

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.svgPageNamePath = this.getSvgAssetByPage();

        const currentUrl = event.urlAfterRedirects.split('?')[0].split('/')[1];
        if (currentUrl !== previousUrl) this.toggleAnimationState();
        previousUrl = currentUrl;
      }
    });
  }

  private toggleAnimationState(): void {
    this.svgState = 'hidden';
    setTimeout(() => {
      this.svgState = 'shown';
    }, 0);
  }

  private getSvgAssetByPage(): string | undefined {
    let path = '';
    let url = this.router.url.split('?')[0];

    if (url.length === 1 && url === '/') {
      path += 'home';
    }

    if (url.includes('profile')) {
      path += 'profile';
    }

    if (url.includes('chart')) {
      path += 'chart';
    }

    if (url.includes('signals')) {
      path += 'signals';
    }

    if (url.includes('assets')) {
      path += 'assets';
    }

    if (path.length) {
      return './assets/images/common/header/' + path + '.svg';
    }

    return undefined;
  }
}
