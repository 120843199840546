import { Component } from '@angular/core';
import { SubscriptionService } from 'src/app/modules/subscription/subscription.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
})
export class AboutUsComponent {
  constructor(private readonly subscriptionService: SubscriptionService) {}

  public handleSubscribe() {
    this.subscriptionService.openTariffPopup();
  }
}
