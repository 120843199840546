import {
  getAnimationSettings,
  getAxisX,
  getGridSettings,
  getTooltipSettings,
  getWatermark,
} from '../chart-theme';
import { IChartData } from '../../models/chart';
import { EChartsOption } from 'echarts';
import { EDynamicColors } from '../chart-colors';
import { addNullsToData } from '../chart-utils';
import { getStandardDeviation, getSumOfArray } from 'src/app/shared/utils/math';

export function getRsiChartOptions(
  overbought: number,
  oversold: number,
  chartData: IChartData
): EChartsOption {
  //   let data = chartData.data;

  //   let overboughtTreshold = 70;
  //   let oversoldTreshold = 30;

  // если есть данные, рассчитываем пороговые значения
  //   if (data.length) {
  //     let rsiMean = getSumOfArray(data) / data.length;
  //     let rsiStd = getStandardDeviation(data);
  //     overboughtTreshold = Math.round(rsiMean + rsiStd);
  //     oversoldTreshold = Math.round(rsiMean - rsiStd);
  //   }

  const data = addNullsToData<number>(chartData.data);

  return {
    ...getAnimationSettings(),
    graphic: getWatermark(),
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(),
    xAxis: getAxisX(chartData.timestamps),
    yAxis: {
      type: 'value',
      max: 100,
      min: 0,
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: [
      {
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            type: 'dashed',
            color: EDynamicColors.NEUTRAL,
          },
          label: {
            show: true,
            position: 'insideStart',
            color: 'white',
            offset: [30, 0, 0, 0],
            padding: [4, 12, 4, 12],
            borderRadius: [0, 8, 8, 0],
          },
          data: [
            {
              yAxis: oversold,
              label: {
                backgroundColor: EDynamicColors.FAIL,
              },
            },
            {
              yAxis: overbought,
              label: {
                backgroundColor: EDynamicColors.SUCCESS,
              },
            },
          ],
        },
        name: chartData.name,
        type: 'line',
        showSymbol: false,
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderColor: EDynamicColors.NEUTRAL,
            borderWidth: 13,
            opacity: 0.3,
            color: EDynamicColors.NEUTRAL,
          },
        },
        data: data,
      },
    ],
    visualMap: {
      type: 'piecewise',
      show: false,
      dimension: 1,
      pieces: [
        {
          max: overbought,
          min: oversold,
          color: EDynamicColors.NEUTRAL,
        },
        {
          max: 100,
          min: overbought,
          color: EDynamicColors.SUCCESS,
        },
        {
          max: oversold,
          min: 0,
          color: EDynamicColors.FAIL,
        },
      ],
    },
  };
}
