import { Component, Input } from '@angular/core';
import { FavoritesService } from '../../favorites.service';
import { AuthService, EAuthPopUpState } from 'src/app/modules/auth/auth.service';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
  selector: 'app-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss'],
})
export class FavoriteButtonComponent {
  @Input() assetId: number;

  constructor(
    public readonly favoritesService: FavoritesService,
    public readonly userService: UserService,
    public readonly authService: AuthService
  ) {}

  toggleFavorite(event: Event) {
    event.stopPropagation();

    if (!this.userService.isAuthorized) {
      this.authService.setAuthPopUpState(EAuthPopUpState.LOGIN);
      return;
    }

    if (this.isLoading()) return;

    if (this.inFavorites()) {
      this.favoritesService.deleteFavorite(this.assetId);
    } else {
      this.favoritesService.addFavorite(this.assetId);
    }
  }

  inFavorites(): boolean {
    return this.favoritesService.favorites.includes(this.assetId);
  }

  isLoading(): boolean {
    return this.favoritesService.fetchingId == this.assetId && this.favoritesService.isLoading;
  }
}

@Component({
  selector: 'app-chart-favorite-button',
  templateUrl: './chart-favorite-button.component.html',
  styleUrls: ['./chart-favorite-button.component.scss'],
})
export class ChartFavoriteButtonComponent extends FavoriteButtonComponent {
  public showLoginWindow() {
    this.authService.setAuthPopUpState(EAuthPopUpState.LOGIN);
  }
}
