import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, throttleTime } from 'rxjs';
import { SearchNameService } from "../../../../../modules/assets/search-name.service";
import { AssetsService } from "../../../../../modules/assets/assets.service";
import { IAsset } from "../../../../../modules/assets/models/assets";
import { Router } from "@angular/router";

interface IEffectCoords {
  id: number;
  x: number;
  y: number;
  duration: number;
}

@Component({
  selector: 'app-welcome-banner',
  templateUrl: './welcome-banner.component.html',
  styleUrls: ['./welcome-banner.component.scss'],
})
export class WelcomeBannerComponent implements AfterViewInit {
  @ViewChild('hoverPanel') hoverPanel: ElementRef;

  public effects: IEffectCoords[] = [];

  constructor(
      private readonly _router: Router,
      public readonly searchNameService: SearchNameService,
      public readonly assetsService: AssetsService,
  ) {}

  public animationEndHandler(effect: IEffectCoords) {
    this.effects = this.effects.filter((element) => element.id != effect.id);
  }

  setSearchLine(line: string): void {
    line = line.trim();
    if (line) {
      this.searchNameService.fetchNames(line);
    } else {
      this.searchNameService.assets = [];
    }
  }

  setSearchValue(value: IAsset | null): void {
    if (value) {
      this._router.navigate(["/chart"], {
        queryParams: {isin: value.isin},
        queryParamsHandling: 'merge',
      });
    }
  }

  ngAfterViewInit(): void {
    const panelElement: HTMLDivElement = this.hoverPanel.nativeElement;

    fromEvent(panelElement, 'mousemove')
      .pipe(throttleTime(60))
      .subscribe((event: any) => {
        const rect: DOMRect = panelElement.getBoundingClientRect();

        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (this.effects.length <= 15) {
          const angle = Math.random() * Math.PI * 2;
          const radius = Math.random() * 130;

          const id = Date.now();
          const axisX = x + radius * Math.cos(angle);
          const axisY = y - radius * Math.sin(angle);
          const duration = Math.floor(Math.random() * (800 - 400 + 1) + 400);

          this.effects.push({ id, x: axisX, y: axisY, duration });
        } else {
          this.effects.shift();
        }
      });
  }
}
