import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'valueSign',
})
export class ValueSignPipe implements PipeTransform {
  transform(value: number): string {
    const addSign: string = value >= 0 ? '+' : '-';
    return `${addSign}${Math.abs(value)}`;
  }
}
