<table class="table table--unavalible">
    <tr class="table__row table__row--header">
        <th class="table__col table__col-type">Тип актива</th>
        <th class="table__col table__col-name">Актив</th>
        <th class="table__col table__col-rsi">RSI для юр. лиц</th>
        <th class="table__col table__col-rsi">RSI для физ. лиц</th>
    </tr>
    <tr *ngFor="let asset of assets.slice(0, SHOWN_LIMIT * page)" class="table__row" [title]="asset.name">
        <th class="table__col table__col-type">{{ assetTypesNames[asset.type] }}</th>
        <th class="table__col table__col-name">{{ asset.name }}</th>
        <th class="table__col table__col-rsi">
            <div class="flex items-center small:justify-center justify-end">
                <div class="indicator {{ getRsiColorClass(null) }}"></div>
                <span class="ml-2">NN</span>
            </div>
        </th>
        <th class="table__col table__col-rsi">
            <div class="flex items-center small:justify-center justify-end">
                <div class="indicator {{ getRsiColorClass(null) }}"></div>
                <span class="ml-2">NN</span>
            </div>
        </th>
    </tr>
    <tr *ngIf="assets.length > SHOWN_LIMIT" class="table__row !p-0">
        <div class="table__more" title="Показать ещё"
            (click)="page * SHOWN_LIMIT > assets.length ? hideShown() : nextPage()">
            {{ page * SHOWN_LIMIT > assets.length ? 'Скрыть' : 'Показать ещё' }}
        </div>
    </tr>
</table>