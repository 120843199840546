import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'icon-arrow-up',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      width="24"
      height="24"
      rx="12"
      transform="matrix(1 0 0 -1 0 24.0002)"
      fill="#14913C"
    />
    <path d="M12 6.50024L17.1962 15.5002H6.80385L12 6.50024Z" fill="white" />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconArrowUpComponent {}

@Component({
  standalone: true,
  selector: 'icon-arrow-down',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect width="24" height="24" rx="12" fill="#EC1C1C" />
    <path d="M12 17.5L17.1962 8.5H6.80385L12 17.5Z" fill="white" />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconArrowDownComponent {}

@Component({
  standalone: true,
  selector: 'icon-fullscreen',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 12V3H12M12 21H3V12"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect x="9" y="9" width="6" height="6" rx="1" fill="white" />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconFullscreenComponent {}

@Component({
  standalone: true,
  selector: 'icon-smallscreen',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    data-slot="icon"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25"
    />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconSmallscreenComponent {}
