import { EChartsOption, GridComponentOption, TooltipComponentOption } from 'echarts';

export function getGridSettings(): GridComponentOption {
  return {
    left: -35,
    right: 0,
    top: 0,
    bottom: 0,
    containLabel: true,
    show: true,
    borderWidth: 0,
    backgroundColor: '#0E0E15',
  };
}

export function getTooltipSettings(): TooltipComponentOption {
  return {
    trigger: 'axis',
    className: 'small:!p-6 small:text-[16px] text-[10px] max-small:!rounded-md',
    axisPointer: {
      animation: true,
      label: {
        formatter: function (params) {
          const date = new Date(params.value);
          return date.toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          });
        },
      },
    },
    confine: true,
    backgroundColor: 'rgba(39, 40, 46, 0.60)',
    borderColor: '#1BCD54',
    position: (point: any, params: any, dom: any, rect: any, size: any) => {
      return [point[0] + 5, point[1] - size.contentSize[1] - 5];
    },
    textStyle: {
      color: '#FFF',
      fontSize: 'inherit',
    },
    transitionDuration: 0,
    extraCssText: `
        box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.30); 
        backdrop-filter: blur(8px); 
        border-radius: 16px 16px 16px 0; 
        z-index: 1;
      `,
  };
}

export function getLoadingOptions(color: string, text: string = 'Загрузка данных'): object {
  return {
    color,
    text,
    textColor: '#FFF',
    fontSize: 18,
    fontFamily: 'Inter',
    fontWeight: 'bold',
    maskColor: 'rgba(0, 0, 0, 0.8)',
  };
}

export function getAnimationSettings() {
  return { animationDelay: 0, animationDuration: 500 };
}

export function getSolidChartOptions(color: string, data: any): EChartsOption {
  return {
    ...getAnimationSettings(),
    color: color,
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(),
    xAxis: {
      type: 'time',
      boundaryGap: ['7%', '1%'],
      axisLabel: {
        hideOverlap: true,
        color: 'white',
        verticalAlign: 'top',
        align: 'center',
      },
      axisLine: {
        lineStyle: {
          color: '#FFF',
        },
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#577775',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: {
      name: 'USD/RUB',
      type: 'line',
      showSymbol: false,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: color },
            { offset: 1, color: 'transparent' },
          ],
        },
      },
      symbolSize: 8,
      emphasis: {
        itemStyle: {
          borderColor: color,
          borderWidth: 13,
          opacity: 0.3,
          color: color,
        },
      },
      data,
    },
  };
}

export function getDoubleChartOptions(firstData: any, secondData: any): EChartsOption {
  return {
    ...getAnimationSettings(),
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(),
    xAxis: {
      type: 'time',
      boundaryGap: ['7%', '1%'],
      axisLabel: {
        hideOverlap: true,
        color: 'white',
        verticalAlign: 'top',
        align: 'center',
      },
      axisLine: {
        lineStyle: {
          color: '#FFF',
        },
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#577775',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: [
      {
        color: '#70FF00',
        name: 'Шорт',
        type: 'line',
        showSymbol: false,
        symbolSize: 8,
        lineStyle: {
          color: '#70FF00',
        },
        emphasis: {
          itemStyle: {
            borderColor: '#70FF00',
            borderWidth: 13,
            opacity: 0.3,
            color: '#70FF00',
          },
        },
        data: firstData,
        markLine: {
          symbol: 'pin',
          symbolOffset: -5,
          emphasis: {
            disabled: true,
          },
          label: {
            show: true,
            position: 'insideStart',
            color: 'black',
            offset: [30, 0, 0, 0],
            backgroundColor: '#70FF00',
            padding: [4, 12, 4, 12],
            borderRadius: [0, 8, 8, 0],
          },
          data: [
            {
              name: 'maximum',
              type: 'max',
            },
          ],
        },
      },
      {
        color: '#FF5C00',
        name: 'Лонг',
        type: 'line',
        showSymbol: false,
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderColor: '#FF5C00',
            borderWidth: 13,
            opacity: 0.3,
            color: '#FF5C00',
          },
        },
        lineStyle: {
          color: '#FF5C00',
        },
        data: secondData,
        markLine: {
          symbol: 'pin',
          symbolOffset: -5,
          emphasis: {
            disabled: true,
          },
          label: {
            show: true,
            position: 'insideStart',
            color: 'black',
            offset: [30, 0, 0, 0],
            backgroundColor: '#FF5C00',
            padding: [4, 12, 4, 12],
            borderRadius: [0, 8, 8, 0],
          },
          lineStyle: {
            cap: 'round',
          },
          data: [
            {
              name: 'maximum',
              type: 'max',
            },
            // {
            //     name: 'coordinate',
            //     coord: [10, 20]
            // }, {
            //     name: 'fixed x position',
            //     yAxis: 10,
            //     x: '90%'
            // },

            // {
            //     name: 'screen coordinate',
            //     x: 100,
            //     y: 100
            // }
          ],
        },
      },
    ],
  };
}

export function getRsiChartOptions(data: any): EChartsOption {
  return {
    ...getAnimationSettings(),
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(),
    xAxis: {
      type: 'time',
      boundaryGap: ['7%', '1%'],
      axisLabel: {
        hideOverlap: true,
        color: 'white',
        verticalAlign: 'top',
        align: 'center',
      },
      axisLine: {
        lineStyle: {
          color: '#FFF',
        },
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#577775',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: [
      {
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            type: 'dashed',
            color: '#CFCFD0',
            opacity: 0.6,
          },
          data: [{ yAxis: '30' }, { yAxis: '70' }],
        },
        name: 'RSI',
        type: 'line',
        showSymbol: false,
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderColor: '#1BCD54',
            borderWidth: 13,
            opacity: 0.3,
            color: '#1BCD54',
          },
        },
        data,
      },
    ],
    visualMap: {
      type: 'piecewise',
      show: false,
      dimension: 1,
      pieces: [
        {
          max: 70,
          min: 30,
          color: '#CFCFD0',
        },
        {
          max: 100,
          min: 70,
          color: '#1BCD54',
        },
        {
          max: 30,
          min: 0,
          color: '#EC1C1C',
        },
      ],
    },
  };
}

export function getFuturesChartOptions(data: any, visualMapPieces: any): EChartsOption {
  return {
    ...getAnimationSettings(),
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(),
    xAxis: {
      type: 'time',
      boundaryGap: ['7%', '1%'],
      axisLabel: {
        hideOverlap: true,
        color: 'white',
        verticalAlign: 'top',
        align: 'center',
      },
      axisLine: {
        lineStyle: {
          color: '#FFF',
        },
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#577775',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: [
      {
        name: 'Фьючерс',
        type: 'line',
        showSymbol: false,
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderColor: '#1BCD54',
            borderWidth: 13,
            opacity: 0.3,
            color: '#1BCD54',
          },
        },
        data,
      },
    ],
    visualMap: {
      show: false,
      dimension: 0,
      pieces: visualMapPieces,
      seriesIndex: 0,
    },
  };
}

export function generateVisualMapPieces(data: number[][]): any {
  let pieces: { min?: number; max?: number; color?: string }[] = [];
  let currentPiece: { min?: number; max?: number; color?: string } = {};

  for (let i = 0; i < data.length; i++) {
    let date = data[i][0];

    let rsiValue = Math.floor(Math.random() * (90 - 10 + 1) + 10);

    let color;
    if (rsiValue < 30) {
      color = '#EC1C1C';
    } else if (rsiValue > 70) {
      color = '#1BCD54';
    } else {
      color = '#CFCFD0';
    }

    if (currentPiece.color === color) {
      currentPiece.max = +date;
    } else {
      if (currentPiece.color) {
        pieces.push(currentPiece);
      }
      currentPiece = {
        min: i == 0 ? +date : +data[i - 1][0],
        max: +date,
        color: color,
      };
    }
  }

  if (currentPiece.color) {
    pieces.push(currentPiece);
  }

  return pieces;
}
