<div class="periods">
    <div class="periods__list">
        <div class="periods__shell" *ngFor="let filter of dateFilters" (click)="changePeriodHandler(filter)">
            <div class="periods__item" [ngClass]="{
                'periods__item--selected': filter.id == chartService.dateFilter.id,
            }">
                <span class="periods__label" [ngClass]="{
                    'phone2:mb-2': filter.id != 5
                }">{{ filter.name }}</span>
                <span *ngIf="filter.id != 5" class="periods__value periods__value--success">
                    С {{ filter.range.from | date:'dd.MM.yyyy' }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="chartService.isChartPage"
        class="desktop:ml-6 desktop:pl-6 desktop:border-l-[1px] max-desktop:mt-4 border-gray3 flex">
        <div class="flex items-start max-desktop:w-full">
            <app-date-input class="desktop:w-[160px] max-desktop:flex-grow" (click)="customClickHandler()"
                (valueChanged)="setFromPeriod($event)" label="От ДД.ММ.ГГГГ" />
            <span class="mx-1 text-[16px] mt-6">-</span>
            <app-date-input class="desktop:w-[160px] max-desktop:flex-grow" (click)="customClickHandler()"
                (valueChanged)="setToPeriod($event)" label="До ДД.ММ.ГГГГ" />
        </div>
        <!-- <div class="h-[78px] py-3 px-4 ml-4 bg-black1 rounded-xl border-[1px] border-white overflow-hidden flex flex-col">
            <span class="mb-2 text-caption whitespace-nowrap">13.08.2018 - 24.06.2021</span>
            <span class="text-numeric-4 text-greenPrimary whitespace-nowrap">(+132,57%)</span>
        </div> -->
    </div>
</div>