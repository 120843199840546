import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LOCAL_STORAGE_KEYS } from 'src/constants';
import { FavoritesRepository } from './repository/favorites';
import { NotifierService } from 'angular-notifier';
import { IAsset } from '../assets/models/assets';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  public isLoading: boolean = false;
  public isAssetsLoading: boolean = false;

  public favorites: number[] = [];
  public fetchingId: number | null = null;

  public assets: BehaviorSubject<IAsset[]> = new BehaviorSubject<IAsset[]>([]);

  constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _favoritesRepository: FavoritesRepository,
    private readonly _notifierService: NotifierService
  ) {}

  public fetchFavorites(): void {
    this.favorites =
      this._localStorageService.getItem<number[]>(LOCAL_STORAGE_KEYS.FAVORITES) || [];

    this.isLoading = true;
    this._favoritesRepository.getFavorites().subscribe((response) => {
      if (response.success) {
        this.favorites = response.data || [];
        this.updateLocalStorage();
      }
      this.isLoading = false;
    });
  }

  public fetchFavoritesAssets(): void {
    this.isAssetsLoading = true;

    this._favoritesRepository.getFavoritesAssets().subscribe((response) => {
      if (response.success) {
        this.assets.next(response.data || []);
      } else if (response.error) {
        console.error(response.error);
        this._notifierService.notify('error', 'Не удалось получить список избранных активов');
      }
      this.isAssetsLoading = false;
    });
  }

  public addFavorite(assetId: number): void {
    this.isLoading = true;
    this.fetchingId = assetId;

    this._favoritesRepository.addFavorite(assetId).subscribe((response) => {
      if (response.success) {
        this.favorites = response.data || [];
        this.updateLocalStorage();
      } else if (response.error) {
        console.error(response.error);
        this._notifierService.notify('error', 'Не удалось добавить актив в избранное');
      }
      this.isLoading = false;
      this.fetchingId = null;
    });
  }

  public deleteFavorite(assetId: number): void {
    this.isLoading = true;
    this.fetchingId = assetId;

    this._favoritesRepository.deleteFavorite(assetId).subscribe((response) => {
      if (response.success) {
        this.favorites = response.data || [];
        this.updateLocalStorage();
      } else if (response.error) {
        console.error(response.error);
        this._notifierService.notify('error', 'Не удалось удалить актив из избранного');
      }
      this.isLoading = false;
      this.fetchingId = null;
    });
  }

  private updateLocalStorage(): void {
    this._localStorageService.setItem(LOCAL_STORAGE_KEYS.FAVORITES, this.favorites);
  }
}
