import { EPositionTypes } from '../models/position-types';

export enum EDynamicColors {
  SUCCESS = '#1BCD54',
  NEUTRAL = '#CFCFD0',
  FAIL = '#EC1C1C',
}

export const individualPositionColors: Partial<Record<EPositionTypes, string>> = {
  [EPositionTypes.SHORT]: '#FF6CB3',
  [EPositionTypes.LONG]: '#0055D3',
  [EPositionTypes.PURE]: '#653FFF',
};

export const legalPositionColors: Partial<Record<EPositionTypes, string>> = {
  [EPositionTypes.SHORT]: '#FF5C00',
  [EPositionTypes.LONG]: '#70FF00',
  [EPositionTypes.PURE]: '#DBFF00',
};
