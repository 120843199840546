<table class="table">
    <tr class="table__row table__row--header">
        <th class="table__col table__col-type">Тип актива</th>
        <th class="table__col table__col-name">Актив</th>
        <th class="table__col table__col-rsi" *ngIf="displayType == EDisplayTypes.POSITIONS">RSI для юр. лиц</th>
        <th class="table__col table__col-rsi" *ngIf="displayType == EDisplayTypes.POSITIONS">RSI для физ. лиц</th>
        <th class="table__col table__col-price" *ngIf="displayType == EDisplayTypes.PRICE">Показатель</th>
    </tr>
    <tr *ngFor="let asset of assets.slice(0, SHOWN_LIMIT * page)" class="table__row" [title]="asset.name">
        <th class="table__col table__col-type">{{ assetTypesNames[asset.type] }}</th>
        <th class="table__col table__col-name">{{ asset.name }}</th>
        <th *ngIf="displayType == EDisplayTypes.POSITIONS" class="table__col table__col-rsi">
            <div class="flex items-center small:justify-center justify-end">
                <div class="indicator {{ getRsiColorClass(asset.rsi?.yur ?? null) }}">
                </div>
                <span class="ml-2 w-[31px]">{{ asset.rsi?.yur?.value || 'NN' }}</span>
            </div>
        </th>
        <th *ngIf="displayType == EDisplayTypes.POSITIONS" class="table__col table__col-rsi">
            <div class="flex items-center small:justify-center justify-end">
                <div class="indicator {{ getRsiColorClass(asset.rsi?.fiz ?? null) }}">
                </div>
                <span class="ml-2 w-[31px]">{{ asset.rsi?.fiz?.value || 'NN' }}</span>
            </div>
        </th>
        <th *ngIf="displayType == EDisplayTypes.PRICE" class="table__col table__col-price">
            <div *ngIf="asset.price && asset.price.value" class="flex items-center justify-end">
                <span class="mr-2 laptop:w-6 w-4 laptop:h-6 h-4 max-phone2:hidden"
                    *ngIf="asset.price.daily_changes_perc">
                    <icon-arrow-down *ngIf="(asset.price.daily_changes_perc || 0) < 0" />
                    <icon-arrow-up *ngIf="(asset.price.daily_changes_perc || 0) > 0" />
                </span>
                <span class="text-numeric-3 max-laptop:text-numeric-4 whitespace-nowrap">
                    {{ asset.price.value }}
                </span>
                <span
                    class="ml-2 px-2 phone2:py-1 rounded-full bg-error text-body-3 max-laptop:text-[12px] whitespace-nowrap"
                    *ngIf="asset.price.daily_changes_perc || asset.price.daily_changes_value" [ngClass]="{
                        'bg-greenPrimary': (asset.price.daily_changes_perc || 0) > 0
                    }">
                    <span *ngIf="asset.price.daily_changes_value">
                        {{ (asset.price.daily_changes_value || 0) | valueSign }}
                    </span>
                    <span *ngIf="asset.price.daily_changes_perc">
                        ({{ (asset.price.daily_changes_perc || 0) | valueSign }}%)
                    </span>
                </span>
            </div>
        </th>
        <th>
            <div class="table__col-favorite">
                <app-favorite-button [assetId]="asset.id" />
            </div>
        </th>
    </tr>
    <tr *ngIf="assets.length > SHOWN_LIMIT" class="table__row !p-0">
        <div class="table__more" title="Показать ещё"
            (click)="page * SHOWN_LIMIT > assets.length ? hideShown() : nextPage()">
            {{ page * SHOWN_LIMIT > assets.length ? 'Скрыть' : 'Показать ещё' }}
        </div>
    </tr>
</table>