import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { ILoginPayloadDTO, ILoginResponseDTO } from '../dto/login.dto';
import { IRegisterPayloadDTO, IRegisterResponseDTO } from '../dto/register.dto';
import { BASE_URL } from '../../../../constants';
import { AuthMapper } from '../auth.mapper';
import {
  IResetPasswordStartPayloadDTO,
  IResetPasswordStartResponseDTO,
} from '../dto/reset-password-start.dto';
import {
  IResetPasswordContinuePayloadDTO,
  IResetPasswordContinueResponseDTO,
} from '../dto/reset-password-continue.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  constructor(private readonly _http: HttpClient, private readonly _authMapper: AuthMapper) {}

  public login(data: ILoginPayloadDTO): Observable<HttpResponse<ILoginResponseDTO>> {
    return this._http
      .post<ILoginResponseDTO>(`${BASE_URL}/api/v1/auth/login`, data, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(retry(1));
  }

  public resetPasswordStart(
    data: IResetPasswordStartPayloadDTO
  ): Observable<IResetPasswordStartResponseDTO> {
    return this._http.post<IResetPasswordStartResponseDTO>(
      `${BASE_URL}/api/v1/password/email`,
      data,
      { withCredentials: true }
    );
  }

  public resetPasswordContinue(
    data: IResetPasswordContinuePayloadDTO
  ): Observable<IResetPasswordContinueResponseDTO> {
    return this._http
      .post<IResetPasswordStartResponseDTO>(`${BASE_URL}/api/v1/password/reset`, data, {
        withCredentials: true,
      })
      .pipe(retry(1));
  }

  public register(data: IRegisterPayloadDTO): Observable<HttpResponse<IRegisterResponseDTO>> {
    return this._http
      .post<IRegisterResponseDTO>(
        `${BASE_URL}/api/v1/register`,
        this._authMapper.registerPayloadToRequest(data),
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(retry(1));
  }

  public logout() {
    this._http
      .post<IRegisterResponseDTO>(`${BASE_URL}/api/v1/auth/logout`, {}, { withCredentials: true })
      .pipe(retry(1))
      .subscribe();
  }
}
