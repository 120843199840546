import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, BASE_URL } from 'src/constants';
import { Observable } from 'rxjs';
import {
  IGetAssetPositionResponseDTO,
  IGetAssetPriceResponseDTO,
  IGetAssetRsiResponseDTO,
} from '../dto/reports';

@Injectable({
  providedIn: 'root',
})
export class ChartRepository {
  constructor(private readonly _http: HttpClient) {}

  public getIndividualPosition(
    assetId: number | string,
    from: string,
    to: string,
    interval: number = 1,
    iz_fiz: boolean = true
  ): Observable<IGetAssetPositionResponseDTO> {
    return this.getReport<IGetAssetPositionResponseDTO>(
      'individual',
      assetId,
      from,
      to,
      interval,
      iz_fiz
    );
  }

  public getLegalPosition(
    assetId: number | string,
    from: string,
    to: string,
    interval: number = 1,
    iz_fiz: boolean = true
  ): Observable<IGetAssetPositionResponseDTO> {
    return this.getReport<IGetAssetPositionResponseDTO>(
      'legal',
      assetId,
      from,
      to,
      interval,
      iz_fiz
    );
  }

  public getPrice(
    assetId: number | string,
    from: string,
    to: string,
    interval: number = 1,
    iz_fiz: boolean = false
  ): Observable<IGetAssetPriceResponseDTO> {
    return this.getReport<IGetAssetPriceResponseDTO>('price', assetId, from, to, interval, iz_fiz);
  }

  public getRsi(
    assetId: number | string,
    from: string,
    to: string,
    interval: number = 1,
    iz_fiz: boolean = true
  ): Observable<IGetAssetRsiResponseDTO> {
    return this.getReport<IGetAssetRsiResponseDTO>('rsi', assetId, from, to, interval, iz_fiz);
  }

  private getReport<T>(
    type: 'individual' | 'legal' | 'price' | 'rsi',
    assetId: number | string,
    from: string,
    to: string,
    interval: number = 1,
    iz_fiz: boolean = true
  ): Observable<T> {
    return this._http.get<T>(`${BASE_URL}${API_URL}/assets/${assetId}/${type}`, {
      params: {
        from,
        to,
        interval,
        iz_fiz,
      },
      withCredentials: true,
    });
  }
}
