import { ITabSelectOption } from 'src/app/components/ui/tabs-select/tabs-select.component';

export enum EChartTypes {
  INDIVIDUAL = 'individual',
  LEGAL = 'legal',
  RSI = 'rsi',
  FUTURES = 'futures',
}

export const ChartTypesAliases: Record<EChartTypes, string> = {
  [EChartTypes.INDIVIDUAL]: 'Покупатели-Продавцы',
  [EChartTypes.LEGAL]: 'Покупки-Продажи',
  [EChartTypes.RSI]: 'Сигналы',
  [EChartTypes.FUTURES]: 'Цена актива',
};

export const chartTypeOptions: ITabSelectOption<EChartTypes>[] = [
  {
    label: ChartTypesAliases[EChartTypes.LEGAL],
    value: EChartTypes.LEGAL,
  },
  {
    label: ChartTypesAliases[EChartTypes.INDIVIDUAL],
    value: EChartTypes.INDIVIDUAL,
  },
  {
    label: ChartTypesAliases[EChartTypes.RSI],
    value: EChartTypes.RSI,
  },
  {
    label: ChartTypesAliases[EChartTypes.FUTURES],
    value: EChartTypes.FUTURES,
  },
];
