import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { assetTypesNames } from 'src/app/modules/assets/models/asset-types';
import { IAsset } from 'src/app/modules/assets/models/assets';
import { getRsiColorClass } from 'src/app/modules/assets/models/rsi';
import { SignalFilterService } from '../../signal-filter.service';

export class BaseAssetsTable {
  public readonly SHOWN_LIMIT: number = 15;
  public page: number = 1;

  public assetTypesNames = assetTypesNames;
  public getRsiColorClass = getRsiColorClass;

  nextPage(): void {
    this.page++;
  }
}

@Component({
  selector: 'app-assets-table',
  templateUrl: './templates/assets-table.component.html',
  styleUrls: ['./assets-table.component.scss'],
})
export class AssetsTableComponent extends BaseAssetsTable implements OnChanges {
  @Input() assets: IAsset[] = [];

  constructor(
    public readonly elRef: ElementRef,
    public readonly signalFilterService: SignalFilterService
  ) {
    super();
  }

  hideShown(): void {
    this.page = 1;
    setTimeout(() => this.elRef.nativeElement.scrollIntoView(), 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assets'] && changes['assets'].previousValue.length) {
      if (
        JSON.stringify(changes['assets'].currentValue) !=
        JSON.stringify(changes['assets'].previousValue)
      ) {
        this.page = 1;
        if (window.innerWidth > 1279 && window.scrollY > 260) {
          this.elRef.nativeElement.scrollIntoView();
        }
      }
    }
  }
}

@Component({
  selector: 'app-unavalible-assets-table',
  templateUrl: './templates/unavalible-assets-table.component.html',
  styleUrls: ['./assets-table.component.scss'],
})
export class UnavalibleAssetsTableComponent extends BaseAssetsTable {
  @Input() assets: IAsset[] = [];

  constructor(public readonly elRef: ElementRef) {
    super();
  }

  hideShown(): void {
    this.page = 1;
    setTimeout(() => this.elRef.nativeElement.scrollIntoView(), 0);
  }
}
