import {
  Component,
  Directive,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { IAsset } from 'src/app/modules/assets/models/assets';

@Directive()
class BaseAssetsList {
  @ViewChild('list') listElement: ElementRef;

  public readonly SHOWN_LIMIT: number = 7;

  private _showMore: boolean = false;

  get showMore(): boolean {
    return this._showMore;
  }

  set showMore(value: boolean) {
    this._showMore = value;
    if (!value && this.listElement) {
      setTimeout(() => {
        this.listElement.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 0);
    }
  }
}

@Component({
  selector: 'app-assets-list',
  templateUrl: './templates/assets-list.component.html',
  styleUrls: ['./assets-list.component.scss'],
})
export class AssetsListComponent extends BaseAssetsList {
  @Input() assets: IAsset[] = [];
}

@Component({
  selector: 'app-unavalible-assets-list',
  templateUrl: './templates/unavalible-assets-list.component.html',
  styleUrls: ['./assets-list.component.scss'],
})
export class UnavalibleAssetsListComponent extends BaseAssetsList {
  @Input() assets: IAsset[] = [];
}
