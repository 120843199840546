<div class="container phone:mb-8 desktop:mb-20">
    <div class="light-ellipse">
        <div class="light-ellipse__circle right-0 top-0"></div>
    </div>

    <h2 class="mb-8">Польза сайта</h2>

    <div
        class="spliced-block-gradient mb-6 py-6 small:px-12 px-6 rounded-3xl rounded-bl-none border-[1px] border-gray2 flex phone:flex-col desktop:flex-row phone:items-start desktop:items-center justify-between flex-wrap">
        <div class="max-w-2xl">
            <p class="small:text-body-1 text-body-2 mb-4">Платформа даёт уникальную аналитику открытого интереса и возможность 
                изучать действия участников срочного рынка.</p>
            <p class="small:text-body-1 text-body-2">Оптимизируйте ваши инвестиционные стратегии на основе движений
                крупных и малых игроков.</p>
        </div>

        <button *ngIf="!userService.isAuthorized" class="phone:mt-6 desktop:mt-0 max-phone2:w-full"
            (click)="handleJoinNowButton()">
            Присоединиться
        </button>
    </div>

    <div class="flex flex-col desktop:flex-row justify-between">
        <div
            class="w-full desktop:w-1/3 h-[322px] flex-1 py-6 px-6 bg-gray1 rounded-3xl rounded-bl-none border-[1px] border-gray3 flex flex-col items-stretch phone:mb-6 desktop:mb-0">
            <img class="mb-6 w-full" loading="lazy" src="./assets/images/home/benefits/benefit-1.webp"
                alt="benefit-img-1">
            <h5 class="mb-4">Защита ваших инвестиций от волатильности рынка</h5>
            <p class="text-body-2">Наш сервис предупреждает когда появляются сильные изменения в открытом интересе участников срочного рынка на основе анализа RSI.</p>
        </div>

        <div
            class="w-full desktop:w-1/3 h-[322px] flex-1 py-6 px-6 bg-gray1 rounded-3xl rounded-bl-none border-[1px] border-gray3 flex flex-col items-stretch desktop:mx-6 phone:mb-6 desktop:mb-0">
            <img class="mb-6 w-full" loading="lazy" src="./assets/images/home/benefits/benefit-2.webp"
                alt="benefit-img-2">
            <h5 class="mb-4">Глубокий графический анализ действий участников рынка</h5>
            <p class="text-body-2">Изучайте действия участников рынка по более чем 100 активам, включая акции, валютные пары,
                индексы и товары на срочном рынке за последние 10 лет.</p>
        </div>

        <div
            class="w-full desktop:w-1/3 h-[322px] flex-1 py-6 px-6 bg-gray1 rounded-3xl rounded-bl-none border-[1px] border-gray3 flex flex-col items-stretch">
            <img class="mb-6 w-full" loading="lazy" src="./assets/images/home/benefits/benefit-3.webp"
                alt="benefit-img-3">
            <h5 class="mb-4">Мониторинг рыночных сигналов</h5>
            <p class="text-body-2">Изучайте какие фьючерсы  были перекуплены и перепроданы участниками рынка на основе анализа нашей платформы в разные периоды времени и узнайте что происходит в нынешних тенденциях.</p>
        </div>
    </div>
</div>