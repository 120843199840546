import { BaseModel } from '../../../core/base-model';
import { UserRole } from '../value-objects/user-role';

interface IUserAuthProps {
  id: number;
  name: string;
  email: string;
  avatar: string | null;
  role: UserRole;
  email_notifications: boolean;
  autoprolong: boolean;
}

export class UserModel extends BaseModel {
  public name: string;
  public email: string;
  public role: UserRole;
  public avatar: string | null;
  public email_notifications: boolean;
  public autoprolong: boolean;

  constructor(props: IUserAuthProps) {
    super(props.id);

    this.name = props.name;
    this.avatar = props.avatar;
    this.email = props.email;
    this.role = props.role;
    this.email_notifications = props.email_notifications;
    this.autoprolong = props.autoprolong;
  }
}
