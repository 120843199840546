import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceFormatPipe } from 'src/app/pipes/price-format.pipe';
import { ChartPanelComponent } from './components/chart-panel/chart-panel.component';
import { AssetPickerComponent } from './components/asset-picker/asset-picker.component';
import { TabsSelectComponent } from 'src/app/components/ui/tabs-select/tabs-select.component';
import { TabsSelectSmallComponent } from 'src/app/components/ui/tabs-select-small/tabs-select-small.component';
import { SelectComponent } from 'src/app/components/ui/controls/select/select.component';
import { BasicUpdateComponent } from 'src/app/components/charts/basic-update/basic-update.component';
import { DateInputComponent } from 'src/app/components/ui/controls/date-input/date-input.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { LoaderDotsComponent } from 'src/app/components/ui/loader-dots/loader-dots.component';
import { ChartService } from './chart.service';
import { ValueSignPipe } from 'src/app/pipes/value-sign.pipe';
import {
  IconArrowDownComponent,
  IconArrowUpComponent,
  IconFullscreenComponent,
  IconSmallscreenComponent,
} from 'src/app/components/icons/icons.component';
import { RouterLink } from '@angular/router';
import { ChartComponent } from './components/chart/chart.component';
import { ChartPeriodComponent } from './components/chart-period/chart-period.component';
import { FavoritesModule } from '../favorites/favorites.module';
import { PopupComponent } from "../../components/ui/popup/popup.component";
import { SubscriptionModule } from "../subscription/subscription.module";

@NgModule({
  providers: [ChartService],
  declarations: [ChartPanelComponent, AssetPickerComponent, ChartComponent, ChartPeriodComponent],
  imports: [
    CommonModule,
    RouterLink,
    PriceFormatPipe,
    TabsSelectComponent,
    TabsSelectSmallComponent,
    SelectComponent,
    BasicUpdateComponent,
    DateInputComponent,
    LoaderDotsComponent,
    ValueSignPipe,
    FavoritesModule,

    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),

    // ------------- Icons
    IconArrowDownComponent,
    IconArrowUpComponent,
    IconFullscreenComponent,
    IconSmallscreenComponent,
    PopupComponent,
    SubscriptionModule,
  ],
  exports: [ChartPanelComponent, AssetPickerComponent],
})
export class ChartModule {}
