import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-loader-dots',
  templateUrl: './loader-dots.component.html',
  styleUrls: ['./loader-dots.component.scss'],
  imports: [CommonModule],
})
export class LoaderDotsComponent {
  @Input() label: string = 'Загрузка...';
  @Input() showLabel: boolean = true;
}
