<div class="container phone:mb-8 desktop:mb-20">
    <div #hoverPanel class="banner" style="background-image: url(./assets/images/home/welcome-img.webp);">
        <h1 class="banner__title">mscinsider</h1>
        <p class="banner__subtitle">
            Сервис мониторинга поведения участников рынка Мосбиржи: аналитика, графики, сигналы движений. 
        </p>
        <div class="banner__search">
            <app-search-bar [possibleValues]="searchNameService.assets" [allowResults]="!searchNameService.isLoading"
                            (valueChange)="setSearchLine($event)" (valueSet)="setSearchValue($event)" />
        </div>

        <span class="banner-effect" *ngFor="let effect of effects" (animationend)="animationEndHandler(effect)"
            [ngStyle]="{
            'top': effect.y + 'px',
            'left': effect.x + 'px',
            'animation-duration': effect.duration + 'ms'
        }"></span>
    </div>
</div>