import { state, style, animate, transition, AnimationMetadata } from '@angular/animations';

const DURATION: number = 300; // ms

export const fullscreenAnimation: AnimationMetadata[] = [
  state(
    'small',
    style({
      opacity: 1,
      position: 'static',
      width: '100%',
      heght: 'auto',
    })
  ),
  state(
    'wide',
    style({
      opacity: 1,
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: 100,
      borderRadius: 0,
      overflowY: 'auto',
    })
  ),
  transition('small => wide', [
    animate(`${DURATION}ms ease`, style({ opacity: 0, transform: 'scale(0.8)' })),
    style({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: 100,
      borderRadius: 0,
    }),
    animate(`${DURATION}ms 100ms ease`, style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition('wide => small', [
    animate(
      `${DURATION}ms ease`,
      style({
        opacity: 0,
        transform: 'scale(0.8)',
      })
    ),
    style({ position: 'static', height: 'auto', width: '100%' }),
    animate(`${DURATION}ms 100ms ease`, style({ opacity: 1, transform: 'scale(1)' })),
  ]),
];

export const skeletonAnimation: AnimationMetadata[] = [
  state('small', style({ opacity: 0, position: 'absolute' })),
  state('wide', style({ opacity: 0.4, position: 'static' })),
  transition('small => wide', [
    animate(`${DURATION}ms ease-out`, style({ opacity: 0.4 })),
    style({ position: 'static' }),
  ]),
  transition('wide => small', [
    animate(`${DURATION}ms ease-out`, style({ opacity: 0 })),
    style({ position: 'abosulte' }),
  ]),
];
