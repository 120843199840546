<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="w-full"
>
  <div
    *ngIf="!isSuccess && emailControl.invalid"
    class="text-body-3 mb-8"
  >
    Введите ваш Логин (email), указанный при регистрации
  </div>

  <div
    *ngIf="(!isSuccess && emailControl.valid) || isLoading"
    class="text-body-3 mb-8"
  >
    На введенный email направляется письмо с ссылкой для восстановления пароля.
  </div>

  <div
    *ngIf="isSuccess"
    class="text-body-3 mb-8 flex flex-col"
  >
    <span class="mb-2">На указанный email было отправлено письмо с ссылкой на сброс пароля.</span>
    <span class="mb-2">Для того, чтобы задать новый пароль, перейдите по полученной ссылке.</span>
    <span class="mb-2">Если вы не получили письмо, проверьте папку «Спам»</span>
  </div>

  <div class="input-group">
    <input
      [ngClass]="{
      'input-control__error': emailControlHasError && emailControl.touched,
      'input-control__success': emailControl.valid
     }"
      class="input-control"
      formControlName="email"
      placeholder="Email"
      type="email"
      autocomplete="username"
      (blur)="this.authService.isOneOfAuthFormIsInvalid = !!emailControlHasError"
    >
    <div
      *ngIf="emailControl.errors?.['email'] && !emailControl.errors?.['required'] && emailControl.touched"
      class="input-control__error"
    >
      Введите корректный адрес эл. почты
    </div>

    <div
      *ngIf="emailControl.errors?.['required'] && emailControl.touched"
      class="input-control__error"
    >
      Поле обязательное
    </div>
  </div>

  <button
    [disabled]="form.invalid || isLoading"
    class="mt-4 w-full"
    type="submit"
  >
    <span *ngIf="!isLoading && !isSuccess">Далее</span>
    <span *ngIf="isSuccess">Ок</span>
    <span *ngIf="isLoading"><app-loader-indicator/></span>
  </button>
</form>
