<div class="flex items-stretch">
    <div class="picker flex-grow min-w-0" *ngIf="chartService.currentAsset" [ngClass]="{
        'picker--active': isOpened,
        'picker--disabled': !isPickerEnabled()
    }" (click)="toggleOpened()">

        <h4 class="picker__asset">
            <span class="picker__asset-text">{{ chartService.currentAsset.name }}</span>
            <span class="picker__asset-icon" [ngClass]="{
                'rotate-180': isOpened
            }">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 15L8.46458 11.4655C8.07383 11.0748 8.0739 10.4413 8.46475 10.0507C8.85537 9.66036 9.48843 9.66047 9.87892 10.051L12 12.172L14.121 10.051C14.5115 9.66049 15.1446 9.66038 15.5352 10.0507C15.926 10.4413 15.9262 11.0748 15.5355 11.4655L12 15.001V15Z"
                        fill="white" />
                </svg>
            </span>
        </h4>

        <div class="picker__stats" *ngIf="chartService.currentAsset.price && chartService.currentAsset.price.value">
            <span class="picker__stats-icon" *ngIf="chartService.currentAsset.price.daily_changes_perc">
                <icon-arrow-down *ngIf="(chartService.currentAsset.price.daily_changes_perc || 0) < 0" />
                <icon-arrow-up *ngIf="(chartService.currentAsset.price.daily_changes_perc || 0) > 0" />
            </span>
            <span class="picker__stats-value">
                {{ chartService.currentAsset.price.value }}
            </span>
            <span class="picker__stats-change"
                *ngIf="chartService.currentAsset.price.daily_changes_perc || chartService.currentAsset.price.daily_changes_value"
                [ngClass]="{
                'picker__stats-change--success': (chartService.currentAsset.price.daily_changes_perc || 0) > 0
            }">
                <span *ngIf="chartService.currentAsset.price.daily_changes_value">
                    {{ (chartService.currentAsset.price.daily_changes_value || 0) | valueSign }}
                </span>
                <span *ngIf="chartService.currentAsset.price.daily_changes_perc">
                    ({{ (chartService.currentAsset.price.daily_changes_perc || 0) | valueSign }}%)
                </span>
            </span>
        </div>

        <div (click)="$event.stopPropagation()" class="picker__assets" [ngClass]="{
            'picker__assets--hidden': !isOpened
        }">
            <div class="picker__types" [ngClass]="{
                '!flex': chartService.isFullscreen
            }">
                <div *ngFor="let option of assetTypesOptions" class="picker__item" [ngClass]="{
                    'picker__item--selected': option.value == assetsService.type
                }" (click)="changeTypeHandler(option.value)">
                    {{ option.label }}
                </div>
            </div>
            <div class="picker__list" #assetsList>
                <div class="picker__item" *ngFor="let asset of avalibleAssets" [title]="asset.name"
                    (click)="changeAssetHandler(asset)" [ngClass]="{
                    'picker__item--selected': chartService.currentAsset.isin == asset.isin
                }">
                    {{asset.name}}
                </div>
                <div class="picker__item picker__item--unavalible" *ngFor="let asset of unavalibleAssets">
                    {{asset.name}}
                </div>
            </div>
        </div>
    </div>
    <app-chart-favorite-button class="ml-2" *ngIf="chartService.currentAsset"
        [assetId]="chartService.currentAsset.id" />
</div>