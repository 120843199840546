import { Component, OnDestroy } from '@angular/core';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { SubscriptionService } from 'src/app/modules/subscription/subscription.service';
import { SignalFilterService } from './signal-filter.service';

@Component({
  selector: 'app-signals',
  templateUrl: './signals.component.html',
  styleUrls: ['./signals.component.scss'],
})
export class SignalsComponent implements OnDestroy {
  constructor(
    public readonly assetsService: AssetsService,
    public readonly signalFilterService: SignalFilterService,
    public readonly subscriptionService: SubscriptionService
  ) {
    window.scrollTo({ top: 0 });
  }

  ngOnDestroy(): void {
    this.assetsService.avalibleAssets = [];
    this.assetsService.unavalibleAssets = [];
  }
}
