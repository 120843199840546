<table class="table">
    <tr class="table__row table__row--header">
        <th class="table__col table__col-type">Тип актива</th>
        <th class="table__col table__col-name">Актив</th>
        <th class="table__col table__col-rsi">Сигналы юр. лиц</th>
        <th class="table__col table__col-rsi">Сигналы физ. лиц</th>
    </tr>
    <tr *ngFor="let asset of assets.slice(0, SHOWN_LIMIT * page)" class="table__row" [title]="asset.name">
        <th class="table__col table__col-type">{{ assetTypesNames[asset.type] }}</th>
        <th class="table__col table__col-name">{{ asset.name }}</th>
        <th class="table__col table__col-rsi">
            <div class="flex items-center small:justify-center justify-end">
                <div class="indicator {{ getRsiColorClass(asset.rsi?.yur ?? null) }}">
                </div>
                <span class="ml-2 w-[31px]">{{ asset.rsi?.yur?.value || 'NN' }}</span>
            </div>
        </th>
        <th class="table__col table__col-rsi">
            <div class="flex items-center small:justify-center justify-end">
                <div class="indicator {{ getRsiColorClass(asset.rsi?.fiz ?? null) }}">
                </div>
                <span class="ml-2 w-[31px]">{{ asset.rsi?.fiz?.value || 'NN' }}</span>
            </div>
        </th>
        <th>
            <div class="table__col-favorite">
                <app-favorite-button [assetId]="asset.id" />
            </div>
            <a class="table__row-link" routerLink="/chart" [queryParams]="{isin: asset.isin}">
                <span>Смотреть график</span>
            </a>
        </th>
    </tr>
    <tr *ngIf="assets.length > SHOWN_LIMIT" class="table__row !p-0">
        <div class="table__more" title="Показать ещё"
            (click)="page * SHOWN_LIMIT > assets.length ? hideShown() : nextPage()">
            {{ page * SHOWN_LIMIT > assets.length ? 'Скрыть' : 'Показать ещё' }}
        </div>
    </tr>
</table>