import { TRSI } from './assets';

export type TRsiStatus = 'overbought' | 'neutral' | 'oversold';

export enum ERsiTypes {
  SUCCESS = 'succes',
  FAIL = 'fail',
  NEUTRAL = 'neutral',
  UNAVALIBLE = 'unavalible',
}

export const RsiColorClasses: Record<ERsiTypes, string> = {
  [ERsiTypes.SUCCESS]: 'bg-greenPrimary',
  [ERsiTypes.FAIL]: 'bg-error',
  [ERsiTypes.NEUTRAL]: 'bg-green4',
  [ERsiTypes.UNAVALIBLE]: 'bg-gray4',
};

export function getRsiColorClass(rsi: TRSI | null): string {
  if (!rsi?.status) return RsiColorClasses[ERsiTypes.UNAVALIBLE];
  if (rsi?.status == 'overbought') return RsiColorClasses[ERsiTypes.SUCCESS];
  if (rsi?.status == 'neutral') return RsiColorClasses[ERsiTypes.NEUTRAL];
  if (rsi?.status == 'oversold') return RsiColorClasses[ERsiTypes.FAIL];
  return RsiColorClasses[ERsiTypes.UNAVALIBLE];
}
