import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IAsset } from "../../../../modules/assets/models/assets";

@Component({
  standalone: true,
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  imports: [CommonModule, FormsModule],
})
export class SearchBarComponent implements OnChanges {
  @Input() possibleValues: IAsset[] = [];
  @Input() allowResults: boolean = true;

  @Output() valueChange = new EventEmitter<string>();
  @Output() valueSet = new EventEmitter<IAsset | null>();

  @ViewChild('valuesContainer') valuesContainer: ElementRef;

  public value: string = '';
  public isValuesOpened: boolean = false;
  public splittedPossibleValues: Array<{value: IAsset, label: string[]}> = [];

  constructor(public elRef: ElementRef) {}

  setValue(value: IAsset | null) {
    this.value = value ? value.name : '';
    this.isValuesOpened = false;
    this.valueSet.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['possibleValues'] || changes['value']) {
      const newValues: IAsset[] = changes['possibleValues'].currentValue;

      this.splittedPossibleValues = newValues.map((asset) => {
        const index = asset.name.toLowerCase().indexOf(this.value.toLowerCase());
        const label = (index > -1) ? [
          asset.name.substring(0, index),
          asset.name.substring(index, index + this.value.length),
          asset.name.substring(index + this.value.length),
        ] : [
          asset.name,
          '',
          '',
        ];

        return {
          value: asset,
          label: label,
        };
      });

      setTimeout(() => {
        this.valuesContainer.nativeElement.scrollTop = 0;
      }, 0);
    }
  }

  allowToShowValues(): boolean {
    return this.allowResults && this.isValuesOpened && this.value.length > 0;
  }

  @HostListener('body:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isValuesOpened = false;
    }
  }
}
