<div class="tariff">
    <div class="tariff__head" [ngClass]="this.tariffTheme.classes.header">
        <h4 class="tariff__name"> {{ tariff.name }}</h4>
        <div class="tariff__info" *ngIf="tariff.description_hover">
            <button class="tariff__info-toggler" (mouseenter)="showHoverDescription=true"
                (click)="showHoverDescription=true" (mouseleave)="showHoverDescription=false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="9" stroke="white" stroke-width="2" />
                    <rect x="11" y="10" width="2" height="8" rx="1" fill="white" />
                    <rect x="11" y="6" width="2" height="2" rx="1" fill="white" />
                </svg>
            </button>
            <div class="tariff__hover" [ngStyle]="{
                opacity: showHoverDescription ? 1: 0,
                visibility: showHoverDescription ? 'visible': 'hidden'
            }">
                {{tariff.description_hover}}
            </div>
        </div>
        <span class="tariff__popular" *ngIf="tariff.id == 3 && !isCurrent()">
            Самый популярный
        </span>
        <span class="tariff__popular tariff__popular--green" *ngIf="isCurrent() ">
            Ваш тариф
        </span>
    </div>
    <div class="tariff__body">
        <p class="tariff__description">
            {{tariff.description}}
        </p>
        <div class="tariff__action spliced-block-gradient">
            <div class="tariff__expires" *ngIf="isCurrent() && !isFree()">
                Истекает <span class="tariff__expires-date" [ngClass]="{
                    '!bg-error': isWithinSevenDays()
                }">{{_subscriptionService.subscription?.expires_at}}</span>
            </div>
            <div class="flex justify-center gap-1 flex-col" [ngClass]="{
                'small:!flex-row-reverse small:items-end justify-between': isCurrent() && !isFree(),
            }">
                <p class="tariff__price">{{tariff.price | priceFormat}} <span class="tariff__currency">₽</span></p>
                <p class="tariff__terms" [ngClass]="{
                    'mb-1 mr-1': isCurrent() && !isFree(),
                }">{{tariff.terms}}</p>
            </div>
            <button class="mt-3 w-full whitespace-nowrap" [disabled]="isAdmin() || (isAutoProlong() && !cancelAutoprolong)"
                *ngIf="!(_userService.isAuthorized && isCurrent() && isFree())"
                [ngClass]="this.tariffTheme.classes.button" (click)="updateTariff()">
                {{ getButtonCaption() }}
            </button>
        </div>
    </div>
</div>