import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements AfterViewInit {
  @ViewChild('analytics') analytics: ElementRef;
  public isVisible = false;
  private observer: IntersectionObserver;

  constructor() {}

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !this.isVisible) {
        this.isVisible = true;
        this.observer.disconnect(); // отключаем observer после первого срабатывания
      }
    });

    this.observer.observe(this.analytics.nativeElement);
  }
}
