import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from "../../shared/services/local-storage.service";
import {LOCAL_STORAGE_KEYS} from "../../../constants";
import {ITokensPersistenceModel} from "../user/persistence-models/tokens";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly _localStorageService: LocalStorageService,
    ) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokens = this._localStorageService.getItem<ITokensPersistenceModel>(LOCAL_STORAGE_KEYS.TOKENS);
        if (!tokens || !tokens.access || !tokens.refresh) {
            return next.handle(req);
        }

        const clonedRequest = req.clone({
            headers: req.headers.append('Authorization', 'Bearer ' + tokens.access).append('RefreshToken', tokens.refresh)
        });

        return next
            .handle(clonedRequest)
            .pipe(map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const access = event.headers.get('token');
                    const refresh = event.headers.get('refresh_token');
                    if (access && refresh && (tokens.access !== access || tokens.refresh !== refresh)) {
                        this._localStorageService.setItem(LOCAL_STORAGE_KEYS.TOKENS, {access: access, refresh: refresh});
                    }
                }
                return event;
            }));
    }
}