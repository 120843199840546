import { Component } from '@angular/core';
import { UserService } from '../../user.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-notifications-toggler',
  templateUrl: './notifiications-toggler.component.html',
})
export class NotificationsTogglerComponent {
  private updateNotifications = new Subject<boolean>();

  constructor(public readonly userService: UserService) {
    this.updateNotifications.pipe(debounceTime(300)).subscribe((value) => {
      this.userService.updateNotifications(value);
    });
  }

  update(value: boolean) {
    this.updateNotifications.next(value);
  }
}
