import { IOption } from 'src/app/components/ui/controls/select/select.component';

export enum EAssetTypes {
  CURRENCY = 'currency',
  STOCK = 'stock',
  PRODUCT = 'product',
  INDEX = 'index',
  OTHER = 'other',
}

export const assetTypesNames: Record<EAssetTypes, string> = {
  [EAssetTypes.CURRENCY]: 'Валютная пара',
  [EAssetTypes.STOCK]: 'Акция',
  [EAssetTypes.PRODUCT]: 'Товар',
  [EAssetTypes.INDEX]: 'Индекс',
  [EAssetTypes.OTHER]: 'Другое',
};

export const assetTypesOptions: IOption<EAssetTypes>[] = [
  {
    label: 'Акции',
    value: EAssetTypes.STOCK,
  },
  {
    label: 'Валютные пары',
    value: EAssetTypes.CURRENCY,
  },
  {
    label: 'Товары',
    value: EAssetTypes.PRODUCT,
  },
  {
    label: 'Индексы',
    value: EAssetTypes.INDEX,
  },
  {
    label: 'Другие',
    value: EAssetTypes.OTHER,
  },
];
