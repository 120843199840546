import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IOption } from 'src/app/components/ui/controls/select/select.component';
import { IToggleItem } from 'src/app/components/ui/controls/toggler/toggler.component';
import { EAssetTypes, assetTypesOptions } from 'src/app/modules/assets/models/asset-types';
import { IAsset } from 'src/app/modules/assets/models/assets';
import { FavoritesService } from 'src/app/modules/favorites/favorites.service';
import { UserService } from 'src/app/modules/user/user.service';

export enum EDisplayTypes {
  PRICE = 'price',
  POSITIONS = 'positions',
}

const togglerItems: IToggleItem<EDisplayTypes>[] = [
  { label: 'Цены', value: EDisplayTypes.PRICE },
  { label: 'Сигналы', value: EDisplayTypes.POSITIONS },
];

const allAssetTypesOptions: IOption<EAssetTypes | null>[] = [
  { label: 'Все активы', value: null },
  ...assetTypesOptions,
];

@Component({
  selector: 'app-profile-subscriptions',
  templateUrl: './profile-subscriptions.component.html',
  styleUrls: ['./profile-subscriptions.component.scss'],
})
export class ProfileSubscriptionsComponent implements OnInit, OnDestroy {
  public currentType: EDisplayTypes = EDisplayTypes.PRICE;
  public searchString: string = '';
  public currentAssetsType: EAssetTypes | null = null;

  public togglerItems = togglerItems;
  public assetTypesOptions = allAssetTypesOptions;

  private subscription: Subscription;
  public filteredAssets: IAsset[] = [];

  constructor(
    public readonly favoritesService: FavoritesService,
    public readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.favoritesService.fetchFavoritesAssets();
    this.subscription = this.favoritesService.assets.subscribe(() => {
      this.filterAssets();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeTypeHandler(newType: EDisplayTypes): void {
    this.currentType = newType;
    this.filterAssets();
  }

  changeSearchHandler(newLine: string): void {
    this.searchString = newLine;
    this.filterAssets();
  }

  changeAssetsTypeHandler(newType: EAssetTypes): void {
    this.currentAssetsType = newType;
    this.filterAssets();
  }

  filterAssets(): void {
    const rootAssets: IAsset[] = this.favoritesService.assets.getValue();
    this.filteredAssets = rootAssets.filter((asset: IAsset): boolean => {
      const searchString = this.searchString.toLowerCase();

      if (!asset.name.toLowerCase().includes(searchString)) {
        return false;
      }

      if (this.currentAssetsType != null) {
        if (asset.type != this.currentAssetsType) {
          return false;
        }
      }

      return true;
    });
  }

  getCurrentType(): IToggleItem<EDisplayTypes> {
    return this.togglerItems.find((item) => item.value == this.currentType) || this.togglerItems[0];
  }

  getCurrentAssetsType(): IOption<EAssetTypes | null> {
    return (
      this.assetTypesOptions.find((option) => option.value == this.currentAssetsType) ||
      this.assetTypesOptions[0]
    );
  }
}
