export interface IDateFilter {
  id: number;
  name?: string;
  range: {
    from: Date;
    to: Date;
  };
  daysInterval: number;
}

export const dateFilters: IDateFilter[] = [
  {
    id: 1,
    name: '1 месяц',
    range: {
      from: new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    daysInterval: 1,
  },
  {
    id: 2,
    name: '6 месяцев',
    range: {
      from: new Date(new Date().getTime() - 183 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    daysInterval: 1,
  },
  {
    id: 3,
    name: '1 год',
    range: {
      from: new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    daysInterval: 1,
  },
  {
    id: 4,
    name: '2 года',
    range: {
      from: new Date(new Date().getTime() - 730 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    daysInterval: 1,
  },
  {
    id: 5,
    name: 'За все время',
    range: {
      from: new Date(1900, 1, 1),
      to: new Date(2100, 1, 1),
    },
    daysInterval: 1,
  },
];
