import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import type { EChartsOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import {
  getDoubleChartOptions,
  getRsiChartOptions,
  getLoadingOptions,
  getSolidChartOptions,
  getFuturesChartOptions,
} from 'src/app/components/charts/chart-themes';
import { SelectComponent } from '../../ui/controls/select/select.component';
import { EChartTypes } from 'src/app/modules/chart/models/chart-types';

@Component({
  standalone: true,
  selector: 'app-basic-update',
  templateUrl: './basic-update.component.html',
  styleUrls: ['./basic-update.component.scss'],
  imports: [CommonModule, NgxEchartsModule, SelectComponent],
})
export class BasicUpdateComponent implements OnInit, OnChanges {
  @Input() currentType: string = '';
  options: EChartsOption;

  public color: string = '#70FF00';
  public loading: boolean = false;
  public loadingOpt: any = {};

  ngOnInit(): void {
    this.updateChartData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentType']) {
      this.showLoading();
    }
  }

  showLoading() {
    this.loading = true;
    setTimeout(() => {
      this.updateChartData();
      this.loading = false;
    }, 1000);
  }

  updateChartData() {
    switch (this.currentType) {
      case EChartTypes.INDIVIDUAL:
        this.loadingOpt = getLoadingOptions(this.color);
        this.options = getSolidChartOptions(this.color, this.generateData());
        break;
      case EChartTypes.LEGAL:
        this.loadingOpt = getLoadingOptions(this.color);
        this.options = getDoubleChartOptions(this.generateData(), this.generateData());
        break;
      case EChartTypes.RSI:
        this.loadingOpt = getLoadingOptions(this.color);
        this.options = getRsiChartOptions(this.generateData());
        break;
      case EChartTypes.FUTURES:
        this.loadingOpt = getLoadingOptions(this.color);
        const data = this.generateData();
        this.options = getFuturesChartOptions(data, this.generateVisualMapPieces(data));
        break;
    }
  }

  generateData() {
    const data = [];
    let currentDate = new Date('2014-01-01');

    let prevValue = null;

    while (currentDate <= new Date('2023-01-01')) {
      let value, rsiValue;

      if (!prevValue) {
        value = Math.floor(Math.random() * (10000 - 4000 + 1) + 4000);
      } else {
        const multiply = Math.random() * (1.1 - 0.9) + 0.9;
        const newValue = Math.round(prevValue * multiply);
        value = newValue <= 0 ? 100 : newValue;
      }

      rsiValue = Math.floor(Math.random() * (90 - 10 + 1) + 10);

      prevValue = value;

      data.push([currentDate.getTime(), this.currentType == 'rsi' ? rsiValue : value]);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return data;
  }

  generateVisualMapPieces(data: number[][]): any {
    let pieces: { min?: number; max?: number; color?: string }[] = [];
    let currentPiece: { min?: number; max?: number; color?: string } = {};

    for (let i = 0; i < data.length; i++) {
      let date = data[i][0];

      let rsiValue = Math.floor(Math.random() * (90 - 10 + 1) + 10);

      let color;
      if (rsiValue < 30) {
        color = '#EC1C1C';
      } else if (rsiValue > 70) {
        color = '#1BCD54';
      } else {
        color = '#CFCFD0';
      }

      if (currentPiece.color === color) {
        currentPiece.max = +date;
      } else {
        if (currentPiece.color) {
          pieces.push(currentPiece);
        }
        currentPiece = {
          min: i == 0 ? +date : +data[i - 1][0],
          max: +date,
          color: color,
        };
      }
    }

    if (currentPiece.color) {
      pieces.push(currentPiece);
    }

    return pieces;
  }
}
