import { IOption } from 'src/app/components/ui/controls/select/select.component';

export enum EPayerTypes {
  INDIVIDUAL = 'individual',
  LEGAL = 'legal',
}

export const PayerTypessAliases: Record<EPayerTypes, string> = {
  [EPayerTypes.INDIVIDUAL]: 'Физ. лица',
  [EPayerTypes.LEGAL]: 'Юр. лица',
};

export const payerTypeOptions: IOption<EPayerTypes>[] = [
  {
    label: PayerTypessAliases[EPayerTypes.LEGAL],
    value: EPayerTypes.LEGAL,
  },
  {
    label: PayerTypessAliases[EPayerTypes.INDIVIDUAL],
    value: EPayerTypes.INDIVIDUAL,
  },
];
