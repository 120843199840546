<div class="search">
    <input type="text" class="search__input" [(ngModel)]="value" (ngModelChange)="valueChange.emit(value)"
        placeholder="Поиск актива" (focus)="isValuesOpened=true" maxlength="50" />
    <span class="search__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path class="svg-stroke"
                d="M21.0002 21L16.6572 16.657M16.6572 16.657C17.4001 15.9141 17.9894 15.0321 18.3914 14.0615C18.7935 13.0909 19.0004 12.0506 19.0004 11C19.0004 9.94936 18.7935 8.90905 18.3914 7.93842C17.9894 6.96779 17.4001 6.08585 16.6572 5.34296C15.9143 4.60007 15.0324 4.01078 14.0618 3.60874C13.0911 3.20669 12.0508 2.99976 11.0002 2.99976C9.9496 2.99976 8.90929 3.20669 7.93866 3.60874C6.96803 4.01078 6.08609 4.60007 5.34321 5.34296C3.84288 6.84329 3 8.87818 3 11C3 13.1217 3.84288 15.1566 5.34321 16.657C6.84354 18.1573 8.87842 19.0002 11.0002 19.0002C13.122 19.0002 15.1569 18.1573 16.6572 16.657Z"
                stroke="#67676C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </span>
    <span class="search__clear" *ngIf="this.value" (click)="setValue(null)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect class="svg-fill" width="16" height="16" rx="8" fill="white" />
            <path d="M10.8281 5.17163L5.17127 10.8285" stroke="#0E0E15" stroke-linecap="round" />
            <path d="M10.8281 10.8284L5.17127 5.17152" stroke="#0E0E15" stroke-linecap="round" />
        </svg>
    </span>
    <div class="search__values" [ngClass]="{
        'search__values--hidden': !(allowToShowValues()),
    }">
        <ul *ngIf="possibleValues.length" class="search__list" #valuesContainer>
            <li class="search__item" *ngFor="let splittedPossibleValue of splittedPossibleValues"
                (click)="setValue(splittedPossibleValue.value)">
                <span *ngIf="splittedPossibleValue.label[0]">{{ splittedPossibleValue.label[0] }}</span>
                <span *ngIf="splittedPossibleValue.label[1]" class="text-greenPrimary">{{ splittedPossibleValue.label[1] }}</span>
                <span *ngIf="splittedPossibleValue.label[2]">{{ splittedPossibleValue.label[2] }}</span>
            </li>
        </ul>
        <span *ngIf="!possibleValues.length" class="text-body-3 text-gray2 text-center block p-2">Нет результатов</span>
    </div>
</div>