<section class="container !pb-28">
    <h1 class="mb-8">Профиль</h1>

    <div class="light-ellipse">
        <div class="light-ellipse__circle bottom-0 left-0"></div>
    </div>

    <div class="flex phone:flex-col laptop:flex-row justify-between items-center pb-8 mb-8 border-b border-b-gray5">
        <a [ngClass]="{'active spliced-block-gradient': isActive('/profile')}" routerLink="/profile"
            class="profile-menu-item profile-menu-item__responsive group">
            <span class="profile-menu-item__title-group">
                <h4 class="profile-menu-item__title">
                    Мои данные
                </h4>
            </span>

            <span class="profile-menu-item__icon">
                <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2" y="2" width="64" height="64" rx="32" stroke="#3B3B41" stroke-width="4" />
                    <path class="fill-only"
                        d="M50.9588 47.0687C48.7737 43.2337 45.3617 40.2455 41.272 38.5852C43.3057 37.0599 44.808 34.9333 45.566 32.5068C46.324 30.0802 46.2993 27.4767 45.4954 25.0649C44.6915 22.6532 43.1491 20.5555 41.0868 19.069C39.0244 17.5826 36.5467 16.7827 34.0045 16.7827C31.4623 16.7827 28.9845 17.5826 26.9222 19.069C24.8598 20.5555 23.3175 22.6532 22.5136 25.0649C21.7096 27.4767 21.6849 30.0802 22.4429 32.5068C23.2009 34.9333 24.7032 37.0599 26.737 38.5852C22.6473 40.2455 19.2353 43.2337 17.0502 47.0687C16.9148 47.2865 16.8245 47.5292 16.7848 47.7825C16.7451 48.0357 16.7567 48.2944 16.819 48.5431C16.8812 48.7918 16.9928 49.0255 17.1472 49.2302C17.3015 49.4349 17.4955 49.6065 17.7175 49.7347C17.9395 49.863 18.185 49.9453 18.4394 49.9768C18.6938 50.0083 18.952 49.9883 19.1986 49.918C19.4451 49.8477 19.675 49.7285 19.8746 49.5676C20.0742 49.4067 20.2394 49.2073 20.3604 48.9812C23.2483 43.9896 28.3483 41.0125 34.0045 41.0125C39.6607 41.0125 44.7607 43.9912 47.6486 48.9812C47.9108 49.4028 48.3266 49.706 48.8083 49.8265C49.2899 49.9471 49.7995 49.8756 50.2294 49.6272C50.6593 49.3788 50.9757 48.973 51.1118 48.4956C51.248 48.0181 51.1931 47.5065 50.9588 47.0687ZM25.717 28.9C25.717 27.2609 26.203 25.6586 27.1137 24.2957C28.0243 22.9328 29.3187 21.8706 30.833 21.2433C32.3473 20.6161 34.0137 20.452 35.6213 20.7717C37.2289 21.0915 38.7056 21.8808 39.8646 23.0398C41.0237 24.1989 41.813 25.6756 42.1327 27.2832C42.4525 28.8908 42.2884 30.5571 41.6611 32.0715C41.0339 33.5858 39.9716 34.8802 38.6088 35.7908C37.2459 36.7014 35.6436 37.1875 34.0045 37.1875C31.8073 37.185 29.7008 36.311 28.1471 34.7573C26.5935 33.2037 25.7195 31.0972 25.717 28.9Z"
                        fill="#3B3B41" />
                </svg>
            </span>
        </a>

        <a [ngClass]="{'active spliced-block-gradient': isActive('/profile/tariffs')}" routerLink="tariffs"
            class="profile-menu-item profile-menu-item__responsive group mx-6">
            <span class="profile-menu-item__title-group">
                <h4 class="profile-menu-item__title">
                    Мой тариф
                </h4>
                <span class="profile-menu-item__subtitle" [ngClass]="'profile-menu-item__tariff-' + activeTariffType">
                    {{ activeTariffName }}
                </span>
                <span class="profile-menu-item__subtitle">
                    Автоплатёж -
                    <span *ngIf="autoprolong" class="profile-menu-item__prolong-active">Подключен</span>
                    <span *ngIf="!autoprolong" class="profile-menu-item__prolong">Не подключен</span>
                </span>
            </span>

            <span class="profile-menu-item__icon">
                <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M59.5 10.5H24.5C16.768 10.5 10.5 16.768 10.5 24.5V59.5C10.5 67.232 16.768 73.5 24.5 73.5H59.5C67.232 73.5 73.5 67.232 73.5 59.5V24.5C73.5 16.768 67.232 10.5 59.5 10.5Z"
                        stroke="#3B3B41" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M31.5 42L39.375 49L52.5 35" stroke="#3B3B41" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </span>
        </a>

        <a [ngClass]="{'active spliced-block-gradient': isActive('/profile/subscriptions')}" routerLink="subscriptions"
            class="profile-menu-item profile-menu-item__responsive group">
            <div class="profile-menu-item__title-group">
                <h4 class="profile-menu-item__title">
                    Мои подписки
                </h4>
            </div>

            <span class="profile-menu-item__icon">
                <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M51.7778 2H16.2222C8.36751 2 2 8.36751 2 16.2222V51.7778C2 59.6325 8.36751 66 16.2222 66H51.7778C59.6325 66 66 59.6325 66 51.7778V16.2222C66 8.36751 59.6325 2 51.7778 2Z"
                        stroke="#3B3B41" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="fill-only"
                        d="M51.5006 29.9225C51.3901 29.6024 51.189 29.3213 50.9219 29.1131C50.6548 28.905 50.333 28.7789 49.9956 28.75L40.0381 27.2975L35.5756 18.25C35.4323 17.9541 35.2085 17.7046 34.93 17.53C34.6514 17.3554 34.3293 17.2628 34.0006 17.2628C33.6718 17.2628 33.3497 17.3554 33.0712 17.53C32.7926 17.7046 32.5689 17.9541 32.4256 18.25L27.9631 27.28L18.0056 28.75C17.6817 28.796 17.3772 28.9319 17.1266 29.1423C16.8761 29.3526 16.6895 29.629 16.5881 29.94C16.4952 30.2439 16.4869 30.5674 16.564 30.8757C16.641 31.184 16.8006 31.4655 17.0256 31.69L24.2531 38.69L22.5031 48.63C22.4324 48.9597 22.4585 49.3029 22.5783 49.6181C22.698 49.9334 22.9063 50.2073 23.1781 50.407C23.4499 50.6066 23.7735 50.7235 24.1101 50.7436C24.4468 50.7636 24.782 50.686 25.0756 50.52L34.0006 45.8475L42.9256 50.52C43.1712 50.6586 43.4486 50.7309 43.7306 50.73C44.1013 50.7313 44.4628 50.6149 44.7631 50.3975C45.0346 50.203 45.2448 49.9349 45.3688 49.6248C45.4928 49.3147 45.5255 48.9756 45.4631 48.6475L43.7131 38.7075L50.9406 31.7075C51.1932 31.4934 51.38 31.2121 51.4791 30.8961C51.5782 30.5802 51.5857 30.2425 51.5006 29.9225ZM40.7381 36.9225C40.5355 37.1192 40.3836 37.362 40.2952 37.6302C40.2068 37.8984 40.1847 38.1839 40.2306 38.4625L41.4906 45.8125L34.9106 42.3125C34.6548 42.186 34.3734 42.1202 34.0881 42.1202C33.8028 42.1202 33.5213 42.186 33.2656 42.3125L26.6856 45.8125L27.9456 38.4625C27.9915 38.1839 27.9693 37.8984 27.8809 37.6302C27.7926 37.362 27.6406 37.1192 27.4381 36.9225L22.1881 31.6725L29.5556 30.605C29.8391 30.5656 30.1086 30.4572 30.3404 30.2894C30.5723 30.1216 30.7595 29.8995 30.8856 29.6425L34.0006 22.975L37.2906 29.66C37.4166 29.917 37.6038 30.1391 37.8357 30.3069C38.0676 30.4747 38.3371 30.5831 38.6206 30.6225L45.9881 31.69L40.7381 36.9225Z"
                        fill="#3B3B41" />
                </svg>
            </span>
        </a>
    </div>

    <section>
        <router-outlet />
    </section>
</section>