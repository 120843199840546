import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { trigger } from '@angular/animations';
import { fullscreenAnimation, skeletonAnimation } from './fullscreen-animation';
import { ITabSelectOption } from 'src/app/components/ui/tabs-select/tabs-select.component';
import { IOption } from 'src/app/components/ui/controls/select/select.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EAssetTypes, assetTypesOptions } from 'src/app/modules/assets/models/asset-types';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { EChartTypes, chartTypeOptions } from '../../models/chart-types';
import { EPayerTypes, payerTypeOptions } from '../../models/payer-types';
import { ChartService } from '../../chart.service';
import { Subscription, take } from 'rxjs';
import {
  EPositionTypes,
  individualPositionTypeOptions,
  legalPositionTypeOptions,
} from '../../models/position-types';

@Component({
  selector: 'app-chart-panel',
  templateUrl: './chart-panel.component.html',
  styleUrls: ['./chart-panel.component.scss'],
  animations: [
    trigger('fullscreenAnimation', fullscreenAnimation),
    trigger('skeletonAnimation', skeletonAnimation),
  ],
})
export class ChartPanelComponent implements OnInit, OnDestroy {
  @ViewChild('analytics') chartPanel: ElementRef;
  @ViewChild('skeleton') skeletonPanel: ElementRef;

  public positionTypeOptions: IOption<EPositionTypes>[] = [];
  public chartTypeOptions: ITabSelectOption<EChartTypes>[] = chartTypeOptions;
  public payerTypeOptions: IOption<EPayerTypes>[] = payerTypeOptions;
  public assetsTypeOptions: IOption<EAssetTypes>[] = assetTypesOptions;

  public EChartTypes = EChartTypes;

  private subscription: Subscription;

  constructor(
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly assetsService: AssetsService,
    public readonly chartService: ChartService
  ) {}

  ngOnInit(): void {
    // REVIEW Здесь менются начальные значения
    this.chartService.currentPositionType = EPositionTypes.SHORT_LONG;
    this.chartService.currentChartType = chartTypeOptions[0].value;
    this.chartService.currentPayerType = payerTypeOptions[0].value;

    this.positionTypeOptions =
      this.chartService.currentChartType == EChartTypes.INDIVIDUAL
        ? individualPositionTypeOptions
        : legalPositionTypeOptions;

    this.subscription = this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      let isin = params['isin'];
      let currentAsset = this.chartService.currentAsset;

      if (isin && !currentAsset) {
        this.chartService.fetchAssetByIsin(isin);
      } else {
        this.assetsService.resetFilters(EAssetTypes.STOCK);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.chartService.currentAsset = null;
    this.chartService.options = {};
  }

  isShowPositions(): boolean {
    return [EChartTypes.INDIVIDUAL, EChartTypes.LEGAL].includes(this.chartService.currentChartType);
  }

  isShowPayer(): boolean {
    return [
      EChartTypes.INDIVIDUAL,
      EChartTypes.LEGAL,
      EChartTypes.RSI,
      EChartTypes.FUTURES,
    ].includes(this.chartService.currentChartType);
  }

  setChartTypeHandler(type: EChartTypes) {
    if (type == EChartTypes.INDIVIDUAL) {
      this.positionTypeOptions = individualPositionTypeOptions;
    } else if (type == EChartTypes.LEGAL) {
      this.positionTypeOptions = legalPositionTypeOptions;
    }

    this.chartService.setCurrentChartType(type);
  }

  setAssetsTypeHandler(type: EAssetTypes) {
    if (type == this.assetsService.type) return;
    this.assetsService.setType(type);
  }

  toggleFullscreen(): void {
    setTimeout(() => {
      if (this.chartService.isFullscreen) {
        document.body.style.overflow = '';
        this.chartService.isFullscreen = false;
      } else {
        this.skeletonPanel.nativeElement.style.height = `${this.chartPanel.nativeElement.offsetHeight}px`;
        this.skeletonPanel.nativeElement.style.position = 'static';
        document.body.style.overflow = 'hidden';
        this.chartService.isFullscreen = true;
      }
    }, 0);
  }

  getCurrentOption(options: IOption<any>[], value: any): any {
    return options.find((option) => option.value == value) || options[0];
  }
}
