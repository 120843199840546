import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsComponent } from './assets.component';
import { FavoritesModule } from 'src/app/modules/favorites/favorites.module';
import { SubscriptionModule } from 'src/app/modules/subscription/subscription.module';
import { AssetsModule } from 'src/app/modules/assets/assets.module';
import { LoaderDotsComponent } from '../../ui/loader-dots/loader-dots.component';
import {
  AssetsListComponent,
  UnavalibleAssetsListComponent,
} from './assets-list/assets-list.component';
import { RouterLink } from '@angular/router';
import { ValueSignPipe } from 'src/app/pipes/value-sign.pipe';
import {
  IconArrowDownComponent,
  IconArrowUpComponent,
} from '../../icons/icons.component';

@NgModule({
  declarations: [
    AssetsComponent,
    AssetsListComponent,
    UnavalibleAssetsListComponent,
  ],
  imports: [
    CommonModule,
    AssetsModule,
    SubscriptionModule,
    FavoritesModule,
    LoaderDotsComponent,
    RouterLink,
    ValueSignPipe,
    IconArrowUpComponent,
    IconArrowDownComponent,
  ],
})
export class AssetsScreenModule {}
