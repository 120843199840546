<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="w-full mt-4"
>
  <div class="input-group">
    <input
      [ngClass]="{
      'input-control__error': loginControlHasError && loginControl.touched,
      'input-control__success': loginControl.valid
     }"
      class="input-control"
      formControlName="login"
      placeholder="Логин, email"
      type="email"
      autocomplete="username"
      (blur)="this.authService.isOneOfAuthFormIsInvalid = !!loginControlHasError"
    >
    <div
      *ngIf="loginControl.errors?.['required'] && loginControl.touched"
      class="input-control__error"
    >
      Поле обязательное
    </div>
  </div>

  <div class="input-group">
    <input
      [ngClass]="{
      'input-control__error': passwordControlHasError && passwordControl.touched,
      'input-control__success': passwordControl.valid
     }"
      class="input-control"
      formControlName="password"
      placeholder="Пароль, не менее 4 символов"
      [type]="passwordControlType"
      autocomplete="password"
      (blur)="this.authService.isOneOfAuthFormIsInvalid = !!passwordControlHasError"
    >
    <control-password-eye
      [onClick]="togglePasswordControlType"
      [isActive]="passwordControlType === 'text'"
    />

    <div
      *ngIf="passwordControl.errors?.['minlength'] && !passwordControl.errors?.['required'] && passwordControl.touched"
      class="input-control__error"
    >
      Минимальная длина пароля 4 символа
    </div>

    <div
      *ngIf="passwordControl.errors?.['required'] && passwordControl.touched"
      class="input-control__error"
    >
      Поле обязательное
    </div>
  </div>

  <div
    (click)="goToResetPasswordStart()"
    class="mb-8 cursor-pointer flex justify-center w-full text-greenPrimary hover:underline"
  >
    Забыл пароль
  </div>

  <button
    [disabled]="form.invalid || isLoading"
    class="mt-4 w-full"
    type="submit"
  >
    <span *ngIf="!isLoading">Войти</span>
    <span *ngIf="isLoading"><app-loader-indicator/></span>
  </button>
</form>
