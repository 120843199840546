import { Injectable } from '@angular/core';
import { AssetsRepository } from './repository/assets';
import { NotifierService } from 'angular-notifier';
import { Subject, catchError, debounceTime, switchMap } from 'rxjs';
import { IAsset } from "./models/assets";

@Injectable({
  providedIn: 'root',
})
export class SearchNameService {
  public assets: IAsset[] = [];
  public searchLine: Subject<string> = new Subject<string>();
  public isLoading: boolean = false;

  constructor(
    private readonly _assetsRepository: AssetsRepository,
    private readonly _notifierService: NotifierService
  ) {
    this.searchLine
      .pipe(
        debounceTime(600),
        switchMap((searchTerm) => this._assetsRepository.searchAssets(searchTerm)),
        catchError((error) => {
          this.isLoading = false;
          throw error;
        })
      )
      .subscribe((response) => {
        if (response.success) {
          this.assets = response.data.items;
        } else if (response.error) {
          console.error(response.error);
          this._notifierService.notify('error', response.error);
        }
        this.isLoading = false;
      });
  }

  public fetchNames(line: string): void {
    this.isLoading = true;
    this.searchLine.next(line);
  }
}
