import { Component, OnInit } from '@angular/core';
import { SearchNameService } from 'src/app/modules/assets/search-name.service';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { EAssetTypes, assetTypesOptions } from 'src/app/modules/assets/models/asset-types';
import { IOption } from 'src/app/components/ui/controls/select/select.component';
import { ESignalType, SignalFilterService } from '../../signal-filter.service';

const signalTypesOptions: IOption<ESignalType>[] = [
  {
    label: 'Все сигналы',
    value: ESignalType.ALL,
  },
  {
    label: '',
    signalLabels: [{ color: '#1BCD54', text: 'Актив перекуплен ФЛ' }],
    value: ESignalType.INDIVIDUAL_SUCCESS,
  },
  {
    label: '',
    signalLabels: [{ color: '#EC1C1C', text: 'Актив перепродан ФЛ' }],
    value: ESignalType.INDIVIDUAL_FAIL,
  },
  {
    label: '',
    signalLabels: [{ color: '#1BCD54', text: 'Актив перекуплен ЮЛ' }],
    value: ESignalType.LEGAL_SUCCESS,
  },
  {
    label: '',
    signalLabels: [{ color: '#EC1C1C', text: 'Актив перепродан ЮЛ' }],
    value: ESignalType.LEGAL_FAIL,
  },
];

@Component({
  selector: 'app-assets-filter',
  templateUrl: './assets-filter.component.html',
  styleUrls: ['./assets-filter.component.scss'],
})
export class AssetsFilterComponent implements OnInit {
  public isAdditionalOpened: boolean = false;

  public assetTypesOptions: IOption<EAssetTypes>[] = assetTypesOptions;
  public signalTypesOptions: IOption<ESignalType>[] = signalTypesOptions;

  constructor(
    public readonly searchNameService: SearchNameService,
    public readonly assetsService: AssetsService,
    public readonly signalFilterService: SignalFilterService
  ) {}

  ngOnInit(): void {
    this.assetsService.resetFilters();
  }

  setSignalType(option: IOption<ESignalType>): void {
    this.signalFilterService.setSignalType(option.value);
  }

  setIsAdditionalOpened(state: boolean): void {
    this.isAdditionalOpened = state;
    this.assetsService.setType(
      !state && this.assetsService.type ? null : assetTypesOptions[0].value
    );

    if (!state && this.signalFilterService.signalType != signalTypesOptions[0].value) {
      setTimeout(() => {
        this.signalFilterService.setSignalType(signalTypesOptions[0].value);
      }, 150);
    }
  }

  setSearchLine(line: string): void {
    line = line.trim();
    if (line) {
      this.searchNameService.fetchNames(line);
    } else {
      this.searchNameService.assets = [];
    }
  }

  setSearchValue(value: string): void {
    value = value.trim();
    this.assetsService.searchLine = value;
    this.assetsService.fetchAssetsList();
  }

  setTypeValue(option: IOption<EAssetTypes>): void {
    this.assetsService.setType(option.value);
  }

  getCurrentOption(options: IOption<any>[], value: any): any {
    return options.find((option) => option.value == value) || options[0];
  }
}
