import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { EAssetTypes } from 'src/app/modules/assets/models/asset-types';
import { SubscriptionService } from 'src/app/modules/subscription/subscription.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
  public EAssetTypes = EAssetTypes;

  constructor(
    public readonly assetsService: AssetsService,
    public readonly subscriptionService: SubscriptionService
  ) {
    window.scrollTo({ top: 0 });
  }

  ngOnDestroy(): void {
    this.assetsService.avalibleAssets = [];
    this.assetsService.unavalibleAssets = [];
  }

  public ngOnInit(): void {
    this.assetsService.resetFilters(EAssetTypes.STOCK);
  }

  public setTypeHandler(type: EAssetTypes): void {
    if (type == this.assetsService.type) return;
    this.assetsService.resetFilters(type);
  }

  public isActive(type: EAssetTypes): boolean {
    return this.assetsService.type == type;
  }
}
