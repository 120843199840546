import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IAsset } from 'src/app/modules/assets/models/assets';
import { BaseAssetsTable } from '../../../signals/components/assets-table/assets-table.component';
import { EDisplayTypes } from '../profile-subscriptions.component';

@Component({
  selector: 'app-favorites-table',
  templateUrl: './favorites-table.component.html',
  styleUrls: ['./favorites-table.component.scss'],
})
export class FavoritesTableComponent extends BaseAssetsTable implements OnChanges {
  @Input() assets: IAsset[] = [];
  @Input() displayType: EDisplayTypes = EDisplayTypes.POSITIONS;

  public EDisplayTypes = EDisplayTypes;

  constructor(public readonly elRef: ElementRef) {
    super();
  }

  hideShown(): void {
    this.page = 1;
    setTimeout(() => this.elRef.nativeElement.scrollIntoView(), 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assets'] && changes['assets'].previousValue?.length) {
      if (
        JSON.stringify(changes['assets'].currentValue) !=
        JSON.stringify(changes['assets'].previousValue)
      ) {
        this.page = 1;
        if (window.innerWidth > 1279 && window.scrollY > 260) {
          this.elRef.nativeElement.scrollIntoView();
        }
      }
    }
  }
}
