import { Component, Input, OnInit } from '@angular/core';
import { ITariff } from 'src/app/modules/subscription/models/tariff';
import { ITariffTheme, TariffThemes } from './tariff-theme';
import { SubscriptionService } from 'src/app/modules/subscription/subscription.service';
import { AuthService, EAuthPopUpState } from 'src/app/modules/auth/auth.service';
import { UserService } from 'src/app/modules/user/user.service';
import { isWithinDays } from 'src/app/shared/utils/within-seven-days';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup.service';

@Component({
  selector: 'app-tariff-item',
  templateUrl: './tariff-item.component.html',
  styleUrls: ['./tariff-item.component.scss'],
})
export class TariffItemComponent implements OnInit {
  @Input() tariff: ITariff;
  @Input() cancelAutoprolong: boolean = false;

  public showHoverDescription: boolean = false;
  public tariffTheme: ITariffTheme;

  constructor(
    private readonly _authService: AuthService,
    public readonly _userService: UserService,
    private readonly _confirmationPopupService: ConfirmationPopupService,
    public readonly _subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.tariffTheme = TariffThemes[this.tariff.id];
  }

  updateTariff(): void {
    if (this.isAutoProlong()) {
      if (this.cancelAutoprolong) {
        this._confirmationPopupService
            .confirm({
              title: 'Подтвердите действие',
              message: `Отменить автоматическое продление?`,
            })
            .subscribe((answer) => {
              if (answer) {
                this._subscriptionService.cancelAutoprolong();
              }
            });
      }
      return;
    }

    if (this._userService.isAuthorized) {
      this._confirmationPopupService
        .confirm({
          title: 'Подтвердите действие',
          message: `Перейти на тарифный план "${this.tariff.name}"?`,
          needAccept: true,
        })
        .subscribe((answer) => {
          if (answer) {
            this._subscriptionService.fetchNewSubscription(this.tariff.id);
          }
        });
    } else {
      if (this._subscriptionService.isTariffPopupOpened) {
        this._subscriptionService.closeTariffPopup();
      }
      this._authService.setAuthPopUpState(EAuthPopUpState.LOGIN);
    }
  }

  isWithinSevenDays(): boolean {
    if (!this._subscriptionService.subscription?.expires_at) {
      return false;
    }
    return isWithinDays(this._subscriptionService.subscription?.expires_at, 7);
  }

  isAdmin(): boolean {
    return this._userService.currentUser?.role.getValue().name == 'admin';
  }

  isFree(): boolean {
    return this.tariff.role.name == 'free';
  }

  isAutoProlong(): boolean {
    return !!this._userService.currentUser?.autoprolong && this.tariff.id === this._subscriptionService.subscription?.tariff_id;
  }

  isCurrent(): boolean {
    const isCurrent: boolean =
      this._subscriptionService.subscription?.tariff_id == this.tariff.id ||
      (!this._subscriptionService.subscription && this.tariff.role.name == 'free');
    return this._userService.isAuthorized && isCurrent;
  }

  getButtonCaption(): string {
    if (!this._userService.isAuthorized) {
      return 'Присоединиться';
    }

    if (this.isAdmin()) {
      return 'Администратор';
    }

    let caption: string = '';

    if (!this._subscriptionService.subscription) {
      caption = 'Повысить тариф';
    } else {
      const subscriptionTariffId = this._subscriptionService.subscription.tariff_id;

      if (subscriptionTariffId === this.tariff.id) {
        if (this._userService.currentUser?.autoprolong) {
          caption = this.cancelAutoprolong ? 'Отменить продление' : 'Ваш тариф';
        } else {
          caption = 'Продлить подписку';
        }
      } else {
        caption = subscriptionTariffId > this.tariff.id ? 'Перейти' : 'Повысить тариф';
      }
    }

    return caption;
  }
}
