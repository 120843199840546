import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ChartFavoriteButtonComponent,
  FavoriteButtonComponent,
} from './components/favorite-button/favorite-button.component';
import { FavoritesService } from './favorites.service';
import { RouterLink } from '@angular/router';

@NgModule({
  providers: [FavoritesService],
  declarations: [FavoriteButtonComponent, ChartFavoriteButtonComponent],
  imports: [CommonModule, RouterLink],
  exports: [FavoriteButtonComponent, ChartFavoriteButtonComponent],
})
export class FavoritesModule {}
