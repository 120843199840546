import { IUserAuthPersistenceModel } from './persistence-models/user-auth';
import { UserModel } from './models/user';
import { UserRole, UserRoleLevels } from './value-objects/user-role';
import { IUserMePersistenceModel } from './persistence-models/user-me';

export class UserSerializer {
  public static authUserToModel(user: IUserAuthPersistenceModel) {
    return new UserModel({
      id: user.id,
      email: user.email,
      name: user.name,
      avatar: user.avatar,
      role: new UserRole({
        ...user.role,
        name: user.role.name as UserRoleLevels,
      }),
      email_notifications: user.email_notifications,
      autoprolong: user.autoprolong,
    });
  }

  public static meUserToModel(user: IUserMePersistenceModel) {
    return new UserModel({
      id: user.id,
      email: user.email,
      avatar: user.avatar,
      name: user.name,
      role: new UserRole({
        ...user.role,
        name: user.role.name as UserRoleLevels,
      }),
      email_notifications: user.email_notifications,
      autoprolong: user.autoprolong,
    });
  }
}
