<app-popup [isOpened]="confirmationPopupService.isConfirmOpened"
    (closeModalEvent)="confirmationPopupService.closeConfirm()">
    <div class="confirm">
        <h4 class="confirm__title">{{ confirmationPopupService.confirmData.title }}</h4>
        <p class="confirm__message">{{ confirmationPopupService.confirmData.message }}</p>
        <app-checkbox
            *ngIf="confirmationPopupService.confirmData.needAccept" class="confirm__agreement"
            inputId="acceptAgreement" [checked]="confirmationPopupService.accepted" (valueChanged)="confirmationPopupService.setAccepted($event)"
        >
            Я принимаю условия <a href='/assets/agreement.docx'>Пользовательского соглашения</a> и <a href='/assets/public.docx'>Политики конфиденциальности</a>
        </app-checkbox>
        <app-checkbox
            *ngIf="confirmationPopupService.confirmData.needAccept" class="confirm__agreement"
            inputId="acceptRecurring" [checked]="confirmationPopupService.acceptRecurring" (valueChanged)="confirmationPopupService.setAcceptRecurring($event)"
        >
            Я соглашаюсь с <a href="/assets/recurring.docx">политикой рекуррентных платежей</a>
        </app-checkbox>
        <div class="confirm__controls">
            <button class="confirm__confirm" [disabled]="confirmationPopupService.confirmDisabled" (click)="confirm(true)">Подтвердить</button>
            <button class="confirm__cancel button--gray" (click)="confirm(false)">Отменить</button>
        </div>
    </div>
</app-popup>