import {
  getAnimationSettings,
  getAxisX,
  getGridSettings,
  getTooltipSettings,
  getWatermark,
} from '../chart-theme';
import { IChartData } from '../../models/chart';
import { EChartsOption } from 'echarts';
import { isMobileScreen } from 'src/app/shared/utils/mobile';
import { addNullsToData } from '../chart-utils';

export function getDuoChartOptions(chartDatas: IChartData[]): EChartsOption {
  const isMobile: boolean = isMobileScreen();
  const series: object[] = [];

  for (let chartData of chartDatas) {
    let data = chartData.data;

    if (data.length) {
      data = addNullsToData<number>(chartData.data);
    }

    const seria = {
      color: chartData.color,
      name: chartData.name,
      type: 'line',
      showSymbol: false,
      symbolSize: 8,
      lineStyle: {
        color: chartData.color,
      },
      emphasis: {
        itemStyle: {
          borderColor: chartData.color,
          borderWidth: 13,
          opacity: 0.3,
          color: chartData.color,
        },
      },
      data: data,
      markLine: isMobile
        ? {}
        : {
            symbol: 'pin',
            symbolOffset: -5,
            emphasis: {
              disabled: true,
            },
            label: {
              show: true,
              position: 'insideStart',
              color: 'black',
              offset: [30, 0, 0, 0],
              backgroundColor: chartData.color,
              padding: [4, 12, 4, 12],
              borderRadius: [0, 8, 8, 0],
            },
            data: data.length
              ? [
                  {
                    name: 'last',
                    yAxis: chartData.data[chartData.data.length - 1],
                  },
                ]
              : [],
          },
    };
    series.push(seria);
  }

  return {
    ...getAnimationSettings(),
    graphic: getWatermark(),
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(),
    xAxis: getAxisX(chartDatas[0].timestamps),
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series,
  };
}
