import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  imports: [CommonModule, FormsModule],
})
export class DateInputComponent {
  @ViewChild('input') inputElement: ElementRef;

  @Input() label: string = 'ДД.ММ.ГГГ';
  @Output() valueChanged = new EventEmitter();

  public value: string = '';
  public isError: boolean = false;

  numberOnly(event: any): void {
    const inputChar: string = String.fromCharCode(event.charCode);
    const pattern: RegExp = /^\d+$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onInputChange(event: Event): void {
    this.isError = false;

    const element = event.target as HTMLInputElement;
    const inputValue: string = element.value.replace(/\D/g, '');
    let formattedValue: string = '';

    for (let i = 0; i < inputValue.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formattedValue += '.';
      }
      formattedValue += inputValue[i];
    }

    this.value = formattedValue;

    if (formattedValue.length >= 10) {
      if (this.isValidDate(formattedValue)) {
        this.valueChanged.emit(formattedValue);
      } else {
        this.isError = true;
      }
    } else if (formattedValue.length == 0) {
      this.valueChanged.emit('');
    }
  }

  private isValidDate(dateString: string): boolean {
    const dateParts = dateString.split('.');
    if (dateParts.length !== 3) {
      return false;
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }

    const date = new Date(year, month - 1, day);
    return date && date.getMonth() + 1 === month && date.getDate() === day;
  }
}
