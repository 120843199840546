<form class="filter">
    <div class="filter__search">
        <app-search-bar [possibleValues]="searchNameService.assets" [allowResults]="!searchNameService.isLoading"
            (valueChange)="setSearchLine($event)" (valueSet)="setSearchValue($event ? $event.name : '')" />
    </div>
    <div class="filter__checkbox" [ngClass]="{'border-greenPrimary': isAdditionalOpened}">
        <app-checkbox elementId="FILTER_CHECKBOX" [checked]="isAdditionalOpened"
                      (valueChanged)="setIsAdditionalOpened($event)">Фильтры</app-checkbox>
        <div *ngIf="isAdditionalOpened" class="filter__reset" (click)="setIsAdditionalOpened(false)">Сбросить все</div>
    </div>
    <div *ngIf="isAdditionalOpened" class="filter__additional">
        <app-select [options]="assetTypesOptions" [current]="getCurrentOption(assetTypesOptions, assetsService.type)"
            (valueChanged)="setTypeValue($event)" />
        <app-select [options]="signalTypesOptions"
            [current]="getCurrentOption(signalTypesOptions, signalFilterService.signalType)"
            (valueChanged)="setSignalType($event)" />
    </div>
</form>