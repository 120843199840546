<div class="favorites">
    <div class="favorites__left">
        <div class="favorites__filter">
            <app-toggler [items]="togglerItems" [current]="getCurrentType()"
                (valueChanged)="changeTypeHandler($event.value)" />
            <app-search-bar (valueChange)="changeSearchHandler($event)" (valueSet)="changeSearchHandler($event ? $event.name : '')"
                [possibleValues]="[]" [allowResults]="false" />
            <app-select (valueChanged)="changeAssetsTypeHandler($event.value)" [current]="getCurrentAssetsType()"
                [options]="assetTypesOptions" />
        </div>
        <div class="favorites__notify">
            <app-notifications-toggler />
        </div>
    </div>
    <div class="favorites__right">
        <div class="favorites__loader" [ngClass]="{
            'favorites__loader--hidden': !favoritesService.isAssetsLoading
        }">
            <div class="favorites__loader-content">
                <app-loader-dots label="Загрузка активов" [showLabel]="true" />
            </div>
        </div>
        <div class="max-w-full overflow-hidden transition-all" [ngClass]="{
            'opacity-0': favoritesService.isAssetsLoading,
        }">
            <p *ngIf="!filteredAssets.length" class="favorites__notfound">
                Доступных активов не найдено
            </p>
            <app-favorites-table *ngIf="filteredAssets.length" [assets]="filteredAssets" [displayType]="currentType" />
        </div>
    </div>
</div>