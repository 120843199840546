// prettier-ignore
import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IOption } from 'src/app/components/ui/controls/select/select.component';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { EAssetTypes, assetTypesOptions } from 'src/app/modules/assets/models/asset-types';
import { IAsset } from 'src/app/modules/assets/models/assets';
import { ChartService } from '../../chart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset-picker',
  templateUrl: './asset-picker.component.html',
  styleUrls: ['./asset-picker.component.scss'],
})
export class AssetPickerComponent implements OnChanges {
  @Input() avalibleAssets: IAsset[];
  @Input() unavalibleAssets: IAsset[];

  @ViewChild('assetsList') assetsList: ElementRef;

  public isOpened: boolean = false;
  public assetTypesOptions: IOption<EAssetTypes>[] = assetTypesOptions;

  constructor(
    private elRef: ElementRef,
    public readonly router: Router,
    public readonly chartService: ChartService,
    public readonly assetsService: AssetsService
  ) {}

  changeTypeHandler(type: EAssetTypes): void {
    if (!this.isPickerEnabled() || this.assetsService.type == type) return;
    this.assetsService.setType(type);
  }

  changeAssetHandler(asset: IAsset): void {
    if (!this.isPickerEnabled()) return;
    this.isOpened = false;
    if (location.pathname == '/chart') {
      this.router.navigate([], {
        queryParams: {
          isin: asset.isin,
        },
      });
    }
    this.chartService.setCurrentAsset(asset);
    this.assetsService.type = asset.type;
  }

  toggleOpened(): void {
    if (!this.isPickerEnabled()) return;
    this.isOpened = !this.isOpened;
  }

  isPickerEnabled(): boolean {
    return !(this.assetsService.isLoading || this.chartService.isLoading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['avalibleAssets']) {
      setTimeout(() => {
        this.assetsList.nativeElement.scrollTop = 0;
      }, 0);

      const currentAsset = this.chartService.currentAsset;
      const firstAvalibleAsset = this.avalibleAssets[0];

      if (
        (!currentAsset && !this.chartService.isLoading) ||
        currentAsset?.type != this.assetsService.type
      ) {
        this.chartService.setCurrentAsset(firstAvalibleAsset);

        if (location.pathname == '/chart') {
          this.router.navigate([], {
            queryParams: {
              isin: firstAvalibleAsset.isin,
            },
          });
        }
      }
    }
  }

  @HostListener('body:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isOpened = false;
    }
  }
}
