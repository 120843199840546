import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, BASE_URL } from 'src/constants';
import { Observable, retry } from 'rxjs';
import { IFavoritesResponseDTO } from '../dto/favorites.dto';
import { IFavoritesAssetsResponseDTO } from '../dto/favorites-assets.dto';

@Injectable({
  providedIn: 'root',
})
export class FavoritesRepository {
  constructor(private readonly _http: HttpClient) {}

  public getFavoritesAssets(): Observable<IFavoritesAssetsResponseDTO> {
    return this._http
      .get<IFavoritesAssetsResponseDTO>(`${BASE_URL}${API_URL}/assets/favorites`, {
        withCredentials: true,
      })
      .pipe(retry(2));
  }

  public getFavorites(): Observable<IFavoritesResponseDTO> {
    return this._http
      .get<IFavoritesResponseDTO>(`${BASE_URL}${API_URL}/favorites`, {
        withCredentials: true,
      })
      .pipe(retry(2));
  }

  public addFavorite(id: number): Observable<IFavoritesResponseDTO> {
    return this._http
      .post<IFavoritesResponseDTO>(
        `${BASE_URL}${API_URL}/favorites`,
        { asset_id: id },
        { withCredentials: true }
      )
      .pipe(retry(2));
  }

  public deleteFavorite(id: number): Observable<IFavoritesResponseDTO> {
    return this._http
      .delete<IFavoritesResponseDTO>(`${BASE_URL}${API_URL}/favorites?asset_id=${id}`, {
        withCredentials: true,
      })
      .pipe(retry(2));
  }
}
