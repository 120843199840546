<div class="container phone:mb-8 desktop:mb-20">
    <div class="flex flex-row justify-between max-laptop:flex-col">
        <div
            class="pt-6 pb-14 small:px-12 px-6 rounded-bl-none rounded-3xl border-[1px] border-gray3 max-laptop:mb-2 laptop:mr-2 w-full spliced-block-gradient-long relative">
            <h2 class="mb-4">О нас</h2>
            <p class="small:text-body-1 text-body-2 mb-4 max-w-[447px]">
                Мы - 1-ые, кто специализируется на анализе поведения участников рынка на срочном рынке МосБиржи, и предоставляем
                уникальную возможность отслеживать, как действовали участники рынка за последние 10 лет.
            </p>
            <p class="small:text-body-1 text-body-2 max-w-[447px]">
                Будьте в курсе того, когда ваши активы на срочном рынке подвергаются сильным закупам или продажам, и
                принимайте обоснованные решения.
            </p>

            <img src="./assets/images/home/about-us.svg" alt="#" class="absolute top-7 right-7 max-desktop:hidden">
        </div>

        <div class="laptop:w-1/2 flex laptop:flex-col max-laptop:justify-between max-small:flex-col">
            <div
                class="small:px-8 px-6 py-10 rounded-bl-none rounded-3xl border-[1px] border-gray3 laptop:mb-2 spliced-block-gradient--edge small:max-laptop:mr-2 max-small:mb-2">
                <h4>Подпишитесь сейчас и получите доступ к эксклюзивной информации!</h4>
            </div>

            <div
                class="small:px-16 small:py-[58px] p-8 flex items-center justify-center rounded-tr-none rounded-3xl border-[1px] border-gray3 spliced-block-gradient">
                <button class="w-full flex items-center justify-between" (click)="handleSubscribe()">
                    <span>Подписаться</span>

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_470_11455)">
                            <path
                                d="M14.707 5.63598L20.364 11.293C20.5515 11.4805 20.6568 11.7348 20.6568 12C20.6568 12.2651 20.5515 12.5194 20.364 12.707L14.707 18.364C14.5184 18.5461 14.2658 18.6469 14.0036 18.6447C13.7414 18.6424 13.4906 18.5372 13.3052 18.3518C13.1198 18.1664 13.0146 17.9156 13.0123 17.6534C13.01 17.3912 13.1108 17.1386 13.293 16.95L17.243 13H4C3.73478 13 3.48043 12.8946 3.29289 12.7071C3.10536 12.5195 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929C3.48043 11.1053 3.73478 11 4 11H17.243L13.293 7.04998C13.1975 6.95773 13.1213 6.84739 13.0689 6.72538C13.0165 6.60338 12.9889 6.47216 12.9877 6.33938C12.9866 6.2066 13.0119 6.07492 13.0622 5.95202C13.1125 5.82913 13.1867 5.71747 13.2806 5.62358C13.3745 5.52969 13.4861 5.45544 13.609 5.40516C13.7319 5.35487 13.8636 5.32957 13.9964 5.33073C14.1292 5.33188 14.2604 5.35947 14.3824 5.41188C14.5044 5.46428 14.6148 5.54047 14.707 5.63598Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_470_11455">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>