import { Component } from '@angular/core';
import { ChartService } from '../../chart.service';
import { IDateFilter, dateFilters } from '../../models/date-filter';

@Component({
  selector: 'app-chart-period',
  templateUrl: './chart-period.component.html',
  styleUrls: ['./chart-period.component.scss'],
})
export class ChartPeriodComponent {
  public dateFilters: IDateFilter[] = dateFilters;

  public customFrom: Date | null = null;
  public customTo: Date | null = null;

  constructor(public readonly chartService: ChartService) {}

  changePeriodHandler(filter: IDateFilter): void {
    if (filter.id == this.chartService.dateFilter.id) return;
    this.chartService.setDateFilter(filter);
  }

  customClickHandler(): void {
    if (this.dateFilters.find((fitler) => fitler.id == this.chartService.dateFilter.id)) {
      this.updateCustomPeriod();
    }
  }

  updateCustomPeriod(): void {
    if (this.customFrom || this.customTo) {
      this.changePeriodHandler({
        id: Date.now(),
        range: {
          from: this.customFrom || new Date(1900, 1, 1),
          to: this.customTo || new Date(),
        },
        daysInterval: 1,
      });
    } else {
      const currentFilter: IDateFilter =
        this.dateFilters.find((fitler) => fitler.id == this.chartService.dateFilter.id) ||
        this.dateFilters[2];
      this.changePeriodHandler(currentFilter);
    }
  }

  setFromPeriod(from: string): void {
    if (from.length > 0) {
      const [day, month, year] = from.split('.');
      const fromDate = new Date(Number(year), Number(month) - 1, Number(day));
      this.customFrom = fromDate;
    } else {
      this.customFrom = null;
    }
    this.updateCustomPeriod();
  }

  setToPeriod(to: string): void {
    if (to.length > 0) {
      const [day, month, year] = to.split('.');
      const fromDate = new Date(Number(year), Number(month) - 1, Number(day));
      this.customTo = fromDate;
    } else {
      this.customTo = null;
    }
    this.updateCustomPeriod();
  }
}
