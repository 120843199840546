import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './user.service';
import { UserRepository } from './repositories/user';
import { CheckboxComponent } from 'src/app/components/ui/controls/checkbox/checkbox.component';
import { NotificationsTogglerComponent } from './components/notifiications-toggler/notifiications-toggler.component';

@NgModule({
  providers: [UserService, UserRepository],
  declarations: [NotificationsTogglerComponent],
  imports: [CommonModule, CheckboxComponent],
  exports: [NotificationsTogglerComponent],
})
export class UserModule {}
