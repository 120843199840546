import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { SubscriptionService } from 'src/app/modules/subscription/subscription.service';
import { AuthService, EAuthPopUpState } from 'src/app/modules/auth/auth.service';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  @ViewChild('menuContainer') menuContainer!: ElementRef<HTMLDivElement>;

  public showProfile: boolean = false;
  public EAuthPopUpState = EAuthPopUpState;

  constructor(
    private readonly router: Router,
    public readonly authService: AuthService,
    public readonly userService: UserService,
    public readonly _subscriptionService: SubscriptionService,
  ) {
  }

  public isActive(path: string): boolean {
    return location.pathname == path;
  }

  public isActiveRoute(path: string): boolean {
    return this.router.url.includes(path);
  }

  public handleAuthPopUpToggle() {
    let state = EAuthPopUpState.LOGIN;
    if (this.authService.authPopUpState !== EAuthPopUpState.CLOSED) {
      state = EAuthPopUpState.CLOSED;
    }

    this.authService.setAuthPopUpState(state);
  }
}
