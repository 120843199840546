import { Injectable } from '@angular/core';
import { IAsset } from 'src/app/modules/assets/models/assets';

export enum ESignalType {
  ALL,
  INDIVIDUAL_SUCCESS,
  INDIVIDUAL_FAIL,
  LEGAL_SUCCESS,
  LEGAL_FAIL,
}

@Injectable({
  providedIn: 'root',
})
export class SignalFilterService {
  public signalType: ESignalType = ESignalType.ALL;

  public setSignalType(type: ESignalType): void {
    this.signalType = type;
  }

  public filterAssets(assets: IAsset[]): IAsset[] {
    switch (this.signalType) {
      case ESignalType.ALL:
        return assets;
      case ESignalType.INDIVIDUAL_SUCCESS:
        return assets.filter((assets) => assets.rsi?.fiz && assets.rsi.fiz.status == 'overbought');
      case ESignalType.INDIVIDUAL_FAIL:
        return assets.filter((assets) => assets.rsi?.fiz && assets.rsi.fiz.status == 'oversold');
      case ESignalType.LEGAL_SUCCESS:
        return assets.filter((assets) => assets.rsi?.yur && assets.rsi.yur.status == 'overbought');
      case ESignalType.LEGAL_FAIL:
        return assets.filter((assets) => assets.rsi?.yur && assets.rsi.yur.status == 'oversold');
    }
  }
}
