import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface IToggleItem<T> {
  label: string;
  value: T;
}

@Component({
  standalone: true,
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  imports: [CommonModule],
})
export class TogglerComponent {
  @Input() items: IToggleItem<any>[];
  @Input() current: IToggleItem<any>;

  @Output() valueChanged = new EventEmitter<IToggleItem<any>>();

  setItem(item: IToggleItem<any>) {
    if (item == this.current) return;
    this.current = item;
    this.valueChanged.emit(item);
  }
}
