import { Injectable } from '@angular/core';
import { EAssetTypes } from './models/asset-types';
import { IAsset } from './models/assets';
import { AssetsRepository } from './repository/assets';
import { NotifierService } from 'angular-notifier';
import { Subscription, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  public type: EAssetTypes | null = null;
  public searchLine: string = '';

  public isLoading: boolean = false;

  public avalibleAssets: IAsset[] = [];
  public unavalibleAssets: IAsset[] = [];

  private prevSubscription: Subscription | null = null; 
  
  constructor(
    private readonly _assetsRepository: AssetsRepository,
    private readonly _notifierService: NotifierService
  ) {}

  public resetFilters(intialType: EAssetTypes | null = null) {
    this.searchLine = '';
    this.type = intialType;
    this.fetchAssetsList();
  }

  public setType(newType: EAssetTypes | null = null): void {
    this.type = newType;
    this.fetchAssetsList();
  }

  public fetchAssetsList(): void {
    if (this.prevSubscription && !this.prevSubscription.closed) {
        this.prevSubscription.unsubscribe();
    }
    
    this.isLoading = true;
    this.prevSubscription = this._assetsRepository
      .getAssets(this.searchLine, this.type)
      .pipe(
        catchError((error) => {
          this.isLoading = false;
          this.avalibleAssets = [];
          this.unavalibleAssets = [];
          throw error;
        })
      )
      .subscribe((response) => {
        if (response.success) {
          this.avalibleAssets = response.data.avalible;
          this.unavalibleAssets = response.data.unavalible;
        } else if (response.error) {
          console.error(response.error);
          this._notifierService.notify('error', response.error);
        }
        this.isLoading = false;
      });
  }
}
