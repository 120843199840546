<section class="sticky bottom-5 w-full z-50 max-phone2:bottom-0" #menuContainer [ngClass]="{
    'tablet:!relative': isActive('/profile') || isActive('/profile/tariffs'),
   }">
    <div class="m-auto w-full">
        <div
            class="flex flex-col-reverse tablet:flex-row items-center justify-center max-tablet:justify-start max-h-[76px] max-phone2:max-h-[59px] overflow-visible">
            <nav
                class="menu-nav flex flex-row items-center max-phone2:flex-wrap max-phone2:w-full p-2 border-white phone2:border max-phone2:border-t-[1px] phone2:rounded-xl phone2:rounded-bl-none z-10">
                <a [ngClass]="{'active': isActive('/')}" class="menu-item menu-item-home" routerLink="'/'">
                    <svg *ngIf="!isActive('/')" fill="none" height="32" viewBox="0 0 76 32" width="76"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.8278 16.3672C23.8278 20.1336 20.6554 23.1869 16.742 23.1869C12.8287 23.1869 9.65625 20.1336 9.65625 16.3672C9.65625 12.6008 12.8287 9.54755 16.742 9.54755C20.6554 9.54755 23.8278 12.6008 23.8278 16.3672Z"
                            fill="white" />
                        <path clip-rule="evenodd"
                            d="M16.0516 0.734436H16.5632V3.22903V5.72362H16.0516C10.3256 5.72362 5.68385 10.1911 5.68385 15.702C5.68385 21.9477 10.747 27.0108 16.9927 27.0108H17.4339C23.1598 27.0108 27.8016 22.5433 27.8016 17.0324V16.5313H30.3935H32.9855V17.0324C32.9855 25.2988 26.0228 32 17.4339 32H5.68385H0.5V27.0108V15.702C0.5 7.43564 7.46267 0.734436 16.0516 0.734436Z"
                            fill="white" fill-rule="evenodd" />
                        <path
                            d="M33.7502 11.6459C33.7502 13.5001 32.1884 15.0033 30.2618 15.0033C28.3352 15.0033 26.7734 13.5001 26.7734 11.6459C26.7734 9.79166 28.3352 8.28851 30.2618 8.28851C32.1884 8.28851 33.7502 9.79166 33.7502 11.6459Z"
                            fill="white" />
                        <path
                            d="M21.6485 6.71475C23.5751 6.71475 25.1369 5.2116 25.1369 3.35738C25.1369 1.50315 23.5751 0 21.6485 0C19.722 0 18.1602 1.50315 18.1602 3.35738C18.1602 5.21161 19.722 6.71475 21.6485 6.71475Z"
                            fill="white" />
                        <path
                            d="M30.2618 6.71475C32.1884 6.71475 33.7502 5.2116 33.7502 3.35738C33.7502 1.50315 32.1884 0 30.2618 0C28.3352 0 26.7734 1.50315 26.7734 3.35738C26.7734 5.2116 28.3352 6.71475 30.2618 6.71475Z"
                            fill="white" />
                        <path d="M41.0547 25.1579V19.8531H41.781V25.1579H41.0547Z" fill="white" />
                        <path
                            d="M46.3336 19.7258C47.0013 19.7258 47.5259 19.9238 47.9074 20.3199C48.2962 20.7089 48.4907 21.2359 48.4907 21.9007V25.1579H47.7643V21.9007C47.7643 21.4268 47.6323 21.059 47.3681 20.7973C47.1113 20.5356 46.7445 20.4048 46.2676 20.4048C45.7394 20.4048 45.3102 20.5675 44.98 20.8928C44.6498 21.2111 44.4848 21.7062 44.4848 22.3782V25.1579H43.7584V19.8531H44.4848V20.6806C44.8956 20.0441 45.5119 19.7258 46.3336 19.7258Z"
                            fill="white" />
                        <path
                            d="M50.9034 21.2429C50.9034 21.4834 51.0135 21.6744 51.2336 21.8159C51.4537 21.9573 51.7215 22.067 52.037 22.1448C52.3525 22.2226 52.6679 22.311 52.9834 22.41C53.2989 22.502 53.5667 22.6611 53.7868 22.8874C54.0069 23.1067 54.117 23.3967 54.117 23.7574C54.117 24.2101 53.9299 24.5779 53.5557 24.8608C53.1889 25.1438 52.712 25.2852 52.125 25.2852C51.5968 25.2852 51.1456 25.1721 50.7714 24.9457C50.4045 24.7194 50.1477 24.4259 50.001 24.0651L50.6173 23.715C50.72 23.9909 50.9034 24.2101 51.1676 24.3728C51.439 24.5284 51.7582 24.6062 52.125 24.6062C52.4845 24.6062 52.7853 24.539 53.0274 24.4046C53.2696 24.2632 53.3906 24.0474 53.3906 23.7574C53.3906 23.517 53.2806 23.326 53.0605 23.1845C52.8404 23.0431 52.5726 22.9334 52.2571 22.8556C51.9416 22.7778 51.6261 22.6929 51.3106 22.601C50.9951 22.502 50.7274 22.3428 50.5072 22.1235C50.2871 21.8972 50.1771 21.6037 50.1771 21.2429C50.1771 20.8115 50.3532 20.4508 50.7053 20.1608C51.0648 19.8708 51.5161 19.7258 52.059 19.7258C52.5065 19.7258 52.8991 19.8248 53.2365 20.0228C53.574 20.2138 53.8235 20.4755 53.9849 20.8079L53.3796 21.1475C53.1668 20.6523 52.7266 20.4048 52.059 20.4048C51.7435 20.4048 51.472 20.479 51.2446 20.6276C51.0172 20.769 50.9034 20.9742 50.9034 21.2429Z"
                            fill="white" />
                        <path d="M55.711 25.1579V19.8531H56.4373V25.1579H55.711Z" fill="white" />
                        <path
                            d="M63.1469 17.7311H63.8733V25.1579H63.1469V24.1182C62.6407 24.8962 61.8997 25.2852 60.9239 25.2852C60.1388 25.2852 59.4675 25.0165 58.9099 24.4789C58.3597 23.9413 58.0845 23.2835 58.0845 22.5055C58.0845 21.7275 58.3597 21.0697 58.9099 20.5321C59.4675 19.9945 60.1388 19.7258 60.9239 19.7258C61.8997 19.7258 62.6407 20.1148 63.1469 20.8928V17.7311ZM59.4382 24.0015C59.8564 24.4046 60.37 24.6062 60.9789 24.6062C61.5879 24.6062 62.1014 24.4046 62.5196 24.0015C62.9378 23.5912 63.1469 23.0926 63.1469 22.5055C63.1469 21.9184 62.9378 21.4233 62.5196 21.0201C62.1014 20.6099 61.5879 20.4048 60.9789 20.4048C60.37 20.4048 59.8564 20.6099 59.4382 21.0201C59.02 21.4233 58.8109 21.9184 58.8109 22.5055C58.8109 23.0926 59.02 23.5912 59.4382 24.0015Z"
                            fill="white" />
                        <path
                            d="M68.3894 19.7258C69.2038 19.7258 69.8605 20.0052 70.3594 20.5639C70.8656 21.1156 71.1187 21.7699 71.1187 22.5267C71.1187 22.6045 71.1114 22.7142 71.0967 22.8556H66.2654C66.3388 23.3932 66.5736 23.8211 66.9698 24.1394C67.3733 24.4506 67.8685 24.6062 68.4555 24.6062C68.8737 24.6062 69.2332 24.5249 69.534 24.3622C69.8421 24.1924 70.0732 23.9732 70.2273 23.7044L70.8656 24.0651C70.6235 24.44 70.2933 24.7371 69.8751 24.9563C69.4569 25.1756 68.9801 25.2852 68.4445 25.2852C67.5787 25.2852 66.8744 25.0235 66.3315 24.5001C65.7885 23.9767 65.5171 23.3118 65.5171 22.5055C65.5171 21.7133 65.7849 21.052 66.3205 20.5215C66.856 19.991 67.5457 19.7258 68.3894 19.7258ZM68.3894 20.4048C67.8172 20.4048 67.3366 20.571 66.9478 20.9034C66.5662 21.2288 66.3388 21.6532 66.2654 22.1766H70.3704C70.297 21.6178 70.0732 21.1828 69.6991 20.8716C69.3249 20.5604 68.8883 20.4048 68.3894 20.4048Z"
                            fill="white" />
                        <path
                            d="M73.4365 20.7337C73.774 20.09 74.3536 19.7682 75.1753 19.7682V20.4472C74.6764 20.4472 74.2619 20.5887 73.9317 20.8716C73.6016 21.1545 73.4365 21.6001 73.4365 22.2084V25.1579H72.7102V19.8531H73.4365V20.7337Z"
                            fill="white" />
                        <path
                            d="M41.3636 18.6754C41.5102 18.6754 41.6348 18.6295 41.7374 18.5377C41.84 18.4459 41.8913 18.3344 41.8913 18.2033C41.8913 18.0721 41.84 17.9606 41.7374 17.8688C41.6348 17.777 41.5102 17.7311 41.3636 17.7311C41.2171 17.7311 41.0925 17.777 40.9898 17.8688C40.8872 17.9606 40.8359 18.0721 40.8359 18.2033C40.8359 18.3344 40.8872 18.4459 40.9898 18.5377C41.0925 18.6295 41.2171 18.6754 41.3636 18.6754Z"
                            fill="white" />
                        <path
                            d="M56.0508 18.6754C56.1974 18.6754 56.322 18.6295 56.4246 18.5377C56.5272 18.4459 56.5785 18.3344 56.5785 18.2033C56.5785 18.0721 56.5272 17.9606 56.4246 17.8688C56.322 17.777 56.1974 17.7311 56.0508 17.7311C55.9042 17.7311 55.7796 17.777 55.677 17.8688C55.5744 17.9606 55.5231 18.0721 55.5231 18.2033C55.5231 18.3344 55.5744 18.4459 55.677 18.5377C55.7796 18.6295 55.9042 18.6754 56.0508 18.6754Z"
                            fill="white" />
                        <path
                            d="M51.5904 6.71478C52.7167 6.71478 53.6138 7.06911 54.2818 7.77778C54.9628 8.48644 55.3033 9.43554 55.3033 10.6251V16.4716H52.7691V10.7959C52.7691 10.2265 52.625 9.78355 52.3369 9.46718C52.0488 9.15081 51.6428 8.99263 51.1189 8.99263C50.5426 8.99263 50.0908 9.17612 49.7634 9.54311C49.449 9.91009 49.2919 10.4416 49.2919 11.1376V16.4716H46.7576V10.7959C46.7576 10.2265 46.6136 9.78355 46.3254 9.46718C46.0373 9.15081 45.6313 8.99263 45.1074 8.99263C44.5443 8.99263 44.0924 9.17612 43.7519 9.54311C43.4245 9.91009 43.2608 10.4416 43.2608 11.1376V16.4716H40.7266V6.98053H43.2608V7.98658C43.8502 7.13871 44.7604 6.71478 45.9915 6.71478C47.1964 6.71478 48.087 7.17035 48.6632 8.08149C49.3181 7.17035 50.2938 6.71478 51.5904 6.71478Z"
                            fill="white" />
                        <path
                            d="M59.7935 9.67598C59.7935 9.92907 59.9637 10.1379 60.3042 10.3024C60.6579 10.4542 61.0835 10.5934 61.5812 10.72C62.0789 10.8339 62.5765 10.9857 63.0742 11.1756C63.5719 11.3527 63.991 11.6564 64.3315 12.0867C64.6851 12.517 64.8619 13.0548 64.8619 13.7002C64.8619 14.6746 64.4821 15.4275 63.7225 15.959C62.976 16.4779 62.0396 16.7373 60.9132 16.7373C58.8963 16.7373 57.5212 15.9843 56.7878 14.4784L58.988 13.2826C59.2761 14.1051 59.9179 14.5164 60.9132 14.5164C61.8169 14.5164 62.2688 14.2443 62.2688 13.7002C62.2688 13.4471 62.092 13.2446 61.7383 13.0927C61.3978 12.9282 60.9787 12.7827 60.4811 12.6562C59.9834 12.5296 59.4857 12.3714 58.988 12.1816C58.4903 11.9918 58.0647 11.6944 57.7111 11.2895C57.3706 10.8718 57.2003 10.353 57.2003 9.73293C57.2003 8.79648 57.5539 8.06251 58.2611 7.53101C58.9815 6.98686 59.872 6.71478 60.9329 6.71478C61.7318 6.71478 62.4587 6.89195 63.1135 7.24628C63.7684 7.58796 64.2857 8.08149 64.6655 8.72688L62.5045 9.8658C62.1902 9.22041 61.6663 8.89772 60.9329 8.89772C60.6055 8.89772 60.3304 8.96732 60.1078 9.10652C59.8982 9.24572 59.7935 9.43554 59.7935 9.67598Z"
                            fill="white" />
                        <path
                            d="M71.033 16.7373C69.553 16.7373 68.3154 16.2564 67.32 15.2947C66.3378 14.3329 65.8466 13.1434 65.8466 11.726C65.8466 10.3087 66.3378 9.11917 67.32 8.15742C68.3154 7.19566 69.553 6.71478 71.033 6.71478C71.9891 6.71478 72.86 6.93624 73.6458 7.37915C74.4316 7.82207 75.0275 8.41684 75.4335 9.16347L73.2529 10.3973C73.0564 10.005 72.7552 9.69496 72.3492 9.46718C71.9563 9.23939 71.511 9.1255 71.0133 9.1255C70.2537 9.1255 69.6251 9.37227 69.1274 9.8658C68.6297 10.3467 68.3809 10.9668 68.3809 11.726C68.3809 12.4727 68.6297 13.0927 69.1274 13.5863C69.6251 14.0672 70.2537 14.3076 71.0133 14.3076C71.5241 14.3076 71.976 14.2 72.3689 13.9849C72.7749 13.7571 73.0761 13.4471 73.2725 13.0548L75.4728 14.2696C75.0406 15.0163 74.4316 15.6174 73.6458 16.0729C72.86 16.5158 71.9891 16.7373 71.033 16.7373Z"
                            fill="white" />
                    </svg>
                    <svg *ngIf="isActive('/')" fill="none" height="32" viewBox="0 0 75 32" width="75"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.3278 16.3673C23.3278 20.1337 20.1554 23.187 16.242 23.187C12.3287 23.187 9.15625 20.1337 9.15625 16.3673C9.15625 12.6009 12.3287 9.54761 16.242 9.54761C20.1554 9.54761 23.3278 12.6009 23.3278 16.3673Z"
                            fill="#005055" />
                        <path clip-rule="evenodd"
                            d="M15.5516 0.734436H16.0632V3.22903V5.72362H15.5516C9.82563 5.72362 5.18385 10.1911 5.18385 15.702C5.18385 21.9477 10.247 27.0108 16.4927 27.0108H16.9339C22.6598 27.0108 27.3016 22.5433 27.3016 17.0324V16.5313H29.8935H32.4855V17.0324C32.4855 25.2988 25.5228 32 16.9339 32H5.18385H0V27.0108V15.702C0 7.43564 6.96267 0.734436 15.5516 0.734436Z"
                            fill="#0E0E15" fill-rule="evenodd" />
                        <path
                            d="M33.2482 11.646C33.2482 13.5002 31.6864 15.0033 29.7599 15.0033C27.8333 15.0033 26.2715 13.5002 26.2715 11.646C26.2715 9.79172 27.8333 8.28857 29.7599 8.28857C31.6864 8.28857 33.2482 9.79172 33.2482 11.646Z"
                            fill="#0E0E15" />
                        <path
                            d="M21.1485 6.71475C23.0751 6.71475 24.6369 5.2116 24.6369 3.35738C24.6369 1.50315 23.0751 0 21.1485 0C19.222 0 17.6602 1.50315 17.6602 3.35738C17.6602 5.21161 19.222 6.71475 21.1485 6.71475Z"
                            fill="#0E0E15" />
                        <path
                            d="M29.7599 6.71475C31.6864 6.71475 33.2482 5.2116 33.2482 3.35738C33.2482 1.50315 31.6864 0 29.7599 0C27.8333 0 26.2715 1.50315 26.2715 3.35738C26.2715 5.2116 27.8333 6.71475 29.7599 6.71475Z"
                            fill="#0E0E15" />
                        <path d="M40.5508 25.158V19.8531H41.2771V25.158H40.5508Z" fill="#005055" />
                        <path
                            d="M45.8297 19.7258C46.4974 19.7258 47.022 19.9239 47.4035 20.32C47.7923 20.709 47.9868 21.2359 47.9868 21.9008V25.158H47.2604V21.9008C47.2604 21.4269 47.1283 21.0591 46.8642 20.7974C46.6074 20.5357 46.2406 20.4048 45.7637 20.4048C45.2354 20.4048 44.8062 20.5675 44.4761 20.8929C44.1459 21.2112 43.9809 21.7063 43.9809 22.3782V25.158H43.2545V19.8531H43.9809V20.6807C44.3917 20.0441 45.008 19.7258 45.8297 19.7258Z"
                            fill="#005055" />
                        <path
                            d="M50.3995 21.243C50.3995 21.4835 50.5096 21.6745 50.7297 21.8159C50.9498 21.9574 51.2176 22.067 51.5331 22.1448C51.8486 22.2226 52.164 22.311 52.4795 22.4101C52.795 22.502 53.0628 22.6612 53.2829 22.8875C53.503 23.1068 53.6131 23.3968 53.6131 23.7575C53.6131 24.2102 53.426 24.578 53.0518 24.8609C52.6849 25.1438 52.2081 25.2853 51.6211 25.2853C51.0929 25.2853 50.6416 25.1721 50.2675 24.9458C49.9006 24.7194 49.6438 24.4259 49.4971 24.0652L50.1134 23.7151C50.2161 23.9909 50.3995 24.2102 50.6637 24.3729C50.9351 24.5285 51.2543 24.6063 51.6211 24.6063C51.9806 24.6063 52.2814 24.5391 52.5235 24.4047C52.7657 24.2632 52.8867 24.0475 52.8867 23.7575C52.8867 23.517 52.7767 23.326 52.5566 23.1846C52.3365 23.0431 52.0687 22.9335 51.7532 22.8557C51.4377 22.7779 51.1222 22.693 50.8067 22.601C50.4912 22.502 50.2234 22.3429 50.0033 22.1236C49.7832 21.8973 49.6732 21.6037 49.6732 21.243C49.6732 20.8115 49.8493 20.4508 50.2014 20.1608C50.5609 19.8708 51.0122 19.7258 51.5551 19.7258C52.0026 19.7258 52.3951 19.8248 52.7326 20.0229C53.0701 20.2139 53.3196 20.4756 53.481 20.808L52.8757 21.1475C52.6629 20.6524 52.2227 20.4048 51.5551 20.4048C51.2396 20.4048 50.9681 20.4791 50.7407 20.6276C50.5132 20.7691 50.3995 20.9742 50.3995 21.243Z"
                            fill="#005055" />
                        <path d="M55.2071 25.158V19.8531H55.9334V25.158H55.2071Z" fill="#005055" />
                        <path
                            d="M62.643 17.7312H63.3694V25.158H62.643V24.1182C62.1368 24.8963 61.3958 25.2853 60.42 25.2853C59.6349 25.2853 58.9636 25.0165 58.406 24.479C57.8558 23.9414 57.5806 23.2836 57.5806 22.5056C57.5806 21.7275 57.8558 21.0697 58.406 20.5322C58.9636 19.9946 59.6349 19.7258 60.42 19.7258C61.3958 19.7258 62.1368 20.1148 62.643 20.8929V17.7312ZM58.9343 24.0015C59.3525 24.4047 59.866 24.6063 60.475 24.6063C61.084 24.6063 61.5975 24.4047 62.0157 24.0015C62.4339 23.5913 62.643 23.0926 62.643 22.5056C62.643 21.9185 62.4339 21.4234 62.0157 21.0202C61.5975 20.61 61.084 20.4048 60.475 20.4048C59.866 20.4048 59.3525 20.61 58.9343 21.0202C58.5161 21.4234 58.307 21.9185 58.307 22.5056C58.307 23.0926 58.5161 23.5913 58.9343 24.0015Z"
                            fill="#005055" />
                        <path
                            d="M67.8855 19.7258C68.6999 19.7258 69.3566 20.0052 69.8555 20.564C70.3617 21.1157 70.6148 21.77 70.6148 22.5268C70.6148 22.6046 70.6075 22.7142 70.5928 22.8557H65.7615C65.8349 23.3932 66.0697 23.8212 66.4659 24.1395C66.8694 24.4507 67.3646 24.6063 67.9516 24.6063C68.3698 24.6063 68.7293 24.5249 69.0301 24.3623C69.3382 24.1925 69.5693 23.9732 69.7234 23.7045L70.3617 24.0652C70.1196 24.4401 69.7894 24.7371 69.3712 24.9564C68.953 25.1757 68.4761 25.2853 67.9406 25.2853C67.0748 25.2853 66.3705 25.0236 65.8276 24.5002C65.2846 23.9768 65.0132 23.3119 65.0132 22.5056C65.0132 21.7134 65.281 21.052 65.8165 20.5215C66.3521 19.9911 67.0418 19.7258 67.8855 19.7258ZM67.8855 20.4048C67.3133 20.4048 66.8327 20.5711 66.4438 20.9035C66.0623 21.2289 65.8349 21.6532 65.7615 22.1767H69.8665C69.7931 21.6179 69.5693 21.1829 69.1952 20.8717C68.821 20.5605 68.3844 20.4048 67.8855 20.4048Z"
                            fill="#005055" />
                        <path
                            d="M72.9326 20.7337C73.2701 20.0901 73.8497 19.7683 74.6714 19.7683V20.4473C74.1725 20.4473 73.758 20.5887 73.4278 20.8717C73.0977 21.1546 72.9326 21.6002 72.9326 22.2085V25.158H72.2063V19.8531H72.9326V20.7337Z"
                            fill="#005055" />
                        <path
                            d="M40.8617 18.6755C41.0083 18.6755 41.1329 18.6296 41.2355 18.5378C41.3381 18.446 41.3894 18.3345 41.3894 18.2033C41.3894 18.0722 41.3381 17.9607 41.2355 17.8689C41.1329 17.7771 41.0083 17.7312 40.8617 17.7312C40.7151 17.7312 40.5905 17.7771 40.4879 17.8689C40.3853 17.9607 40.334 18.0722 40.334 18.2033C40.334 18.3345 40.3853 18.446 40.4879 18.5378C40.5905 18.6296 40.7151 18.6755 40.8617 18.6755Z"
                            fill="#0E0E15" />
                        <path
                            d="M55.5489 18.6755C55.6954 18.6755 55.82 18.6296 55.9226 18.5378C56.0253 18.446 56.0766 18.3345 56.0766 18.2033C56.0766 18.0722 56.0253 17.9607 55.9226 17.8689C55.82 17.7771 55.6954 17.7312 55.5489 17.7312C55.4023 17.7312 55.2777 17.7771 55.1751 17.8689C55.0725 17.9607 55.0212 18.0722 55.0212 18.2033C55.0212 18.3345 55.0725 18.446 55.1751 18.5378C55.2777 18.6296 55.4023 18.6755 55.5489 18.6755Z"
                            fill="#0E0E15" />
                        <path
                            d="M51.0904 6.71484C52.2167 6.71484 53.1138 7.06918 53.7818 7.77784C54.4628 8.4865 54.8033 9.4356 54.8033 10.6251V16.4716H52.2691V10.796C52.2691 10.2265 52.125 9.78361 51.8369 9.46724C51.5488 9.15087 51.1428 8.99269 50.6189 8.99269C50.0426 8.99269 49.5908 9.17618 49.2634 9.54317C48.949 9.91015 48.7919 10.4417 48.7919 11.1377V16.4716H46.2576V10.796C46.2576 10.2265 46.1136 9.78361 45.8254 9.46724C45.5373 9.15087 45.1313 8.99269 44.6074 8.99269C44.0443 8.99269 43.5924 9.17618 43.2519 9.54317C42.9245 9.91015 42.7608 10.4417 42.7608 11.1377V16.4716H40.2266V6.98059H42.7608V7.98664C43.3502 7.13878 44.2604 6.71484 45.4915 6.71484C46.6964 6.71484 47.587 7.17041 48.1632 8.08155C48.8181 7.17041 49.7938 6.71484 51.0904 6.71484Z"
                            fill="#0E0E15" />
                        <path
                            d="M59.2935 9.67604C59.2935 9.92914 59.4637 10.1379 59.8042 10.3024C60.1579 10.4543 60.5835 10.5935 61.0812 10.7201C61.5789 10.8339 62.0765 10.9858 62.5742 11.1756C63.0719 11.3528 63.491 11.6565 63.8315 12.0868C64.1851 12.517 64.3619 13.0548 64.3619 13.7002C64.3619 14.6746 63.9821 15.4276 63.2225 15.9591C62.476 16.4779 61.5396 16.7374 60.4132 16.7374C58.3963 16.7374 57.0212 15.9844 56.2878 14.4785L58.488 13.2826C58.7761 14.1052 59.4179 14.5165 60.4132 14.5165C61.3169 14.5165 61.7688 14.2444 61.7688 13.7002C61.7688 13.4471 61.592 13.2447 61.2383 13.0928C60.8978 12.9283 60.4787 12.7828 59.9811 12.6562C59.4834 12.5297 58.9857 12.3715 58.488 12.1817C57.9903 11.9919 57.5647 11.6945 57.2111 11.2895C56.8706 10.8719 56.7003 10.3531 56.7003 9.73299C56.7003 8.79654 57.0539 8.06257 57.7611 7.53107C58.4815 6.98692 59.372 6.71484 60.4329 6.71484C61.2318 6.71484 61.9587 6.89201 62.6135 7.24634C63.2684 7.58802 63.7857 8.08155 64.1655 8.72694L62.0045 9.86586C61.6902 9.22047 61.1663 8.89778 60.4329 8.89778C60.1055 8.89778 59.8304 8.96738 59.6078 9.10658C59.3982 9.24578 59.2935 9.4356 59.2935 9.67604Z"
                            fill="#0E0E15" />
                        <path
                            d="M70.533 16.7374C69.053 16.7374 67.8154 16.2565 66.82 15.2947C65.8378 14.333 65.3466 13.1434 65.3466 11.7261C65.3466 10.3088 65.8378 9.11924 66.82 8.15748C67.8154 7.19572 69.053 6.71484 70.533 6.71484C71.4891 6.71484 72.36 6.9363 73.1458 7.37921C73.9316 7.82213 74.5275 8.4169 74.9335 9.16353L72.7529 10.3974C72.5564 10.0051 72.2552 9.69502 71.8492 9.46724C71.4563 9.23945 71.011 9.12556 70.5133 9.12556C69.7537 9.12556 69.1251 9.37233 68.6274 9.86586C68.1297 10.3467 67.8809 10.9668 67.8809 11.7261C67.8809 12.4727 68.1297 13.0928 68.6274 13.5863C69.1251 14.0672 69.7537 14.3077 70.5133 14.3077C71.0241 14.3077 71.476 14.2001 71.8689 13.985C72.2749 13.7572 72.5761 13.4471 72.7725 13.0548L74.9728 14.2697C74.5406 15.0163 73.9316 15.6174 73.1458 16.073C72.36 16.5159 71.4891 16.7374 70.533 16.7374Z"
                            fill="#0E0E15" />
                    </svg>
                </a>



                <a [ngClass]="{'active': isActiveRoute('/assets')}" class="menu-item" routerLink="/assets">
                    <svg class="menu-item-icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect height="18" rx="4" stroke="white" stroke-width="2" width="18" x="3" y="3" />
                        <path
                            d="M10.5 17V14.625M10.5 11.75V7H13.625C14.9367 7 16 8.06332 16 9.375V9.5C16 10.7426 14.9926 11.75 13.75 11.75V11.75H10.5ZM10.5 11.75V14.625M10.5 14.625H13M10.5 14.625H9"
                            stroke="white" stroke-linecap="round" stroke-width="2" />
                    </svg>

                    <span class="menu-item-text">Активы</span>
                </a>

                <a [ngClass]="{'active': isActive('/signals')}" class="menu-item" routerLink="/signals">
                    <svg class="menu-item-icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9437 17.2077V18.1558C14.9437 18.9101 14.6336 19.6336 14.0815 20.167C13.5295 20.7003 12.7807 21 12 21C11.2193 21 10.4705 20.7003 9.91846 20.167C9.3664 19.6336 9.05626 18.9101 9.05626 18.1558V17.2077M14.9437 17.2077H9.05626M14.9437 17.2077H18.4664C18.8422 17.2077 19.0306 17.2077 19.1827 17.1584C19.3258 17.1116 19.4557 17.0336 19.5625 16.9303C19.6692 16.827 19.7498 16.7013 19.798 16.563C19.85 16.4151 19.85 16.2331 19.85 15.8672C19.85 15.7069 19.85 15.6273 19.8362 15.5505C19.8121 15.4069 19.754 15.2707 19.6665 15.1523C19.6194 15.0888 19.5605 15.0319 19.4437 14.9191L19.0611 14.5494C19 14.4903 18.9516 14.4203 18.9186 14.3431C18.8856 14.266 18.8687 14.1834 18.8687 14.1V10.5713C18.8687 9.69975 18.6911 8.83677 18.3459 8.0316C18.0007 7.22642 17.4947 6.49482 16.8569 5.87857C16.2191 5.26232 15.4619 4.77348 14.6285 4.43997C13.7952 4.10645 12.902 3.93479 12 3.93479C11.098 3.93479 10.2048 4.10645 9.37145 4.43997C8.5381 4.77348 7.78089 5.26232 7.14307 5.87857C6.50525 6.49482 5.99931 7.22642 5.65412 8.0316C5.30893 8.83677 5.13127 9.69975 5.13127 10.5713V14.1C5.13133 14.1834 5.11438 14.266 5.08138 14.3431C5.04838 14.4203 4.99998 14.4903 4.93894 14.5494L4.55626 14.9191C4.43851 15.0329 4.38061 15.0888 4.3345 15.1514C4.24621 15.2699 4.18744 15.4065 4.16278 15.5505C4.15002 15.6264 4.15002 15.7069 4.15002 15.8672C4.15002 16.2331 4.15002 16.4151 4.20105 16.563C4.24945 16.7015 4.33035 16.8274 4.43742 16.9306C4.54449 17.0339 4.67483 17.1119 4.81825 17.1584C4.97034 17.2077 5.15776 17.2077 5.53358 17.2077H9.05626M17.9061 3C19.2592 3.98542 20.3251 5.29257 21 6.79416M6.09485 3C4.74145 3.9853 3.67522 5.29246 3 6.79416"
                            stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </svg>

                    <span class="menu-item-text">Сигналы</span>
                </a>

                <a [ngClass]="{'active': isActive('/chart')}" class="menu-item" routerLink="/chart">
                    <svg class="menu-item-icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21 21H12L7 21C4.79086 21 3 19.2091 3 17L3 3M7 17L11.0515 11.3279C11.6036 10.555 12.6232 10.277 13.4912 10.6628L14.7536 11.2238C15.7307 11.6581 16.8765 11.247 17.3547 10.2906L21 3"
                            stroke="white" stroke-linecap="round" stroke-width="2" />
                    </svg>

                    <span class="menu-item-text">Графики</span>
                </a>

                <a [ngClass]="{'active': _subscriptionService.isTariffPopupOpened}" class="menu-item"
                    (click)="_subscriptionService.openTariffPopup()">
                    <svg class=" menu-item-icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17 3H7C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z"
                            stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        <path d="M9 12L11.25 14L15 10" stroke="white" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                    </svg>

                    <span class="menu-item-text">Тарифы</span>
                </a>

                <a class="menu-item" href="/assets/guide.pdf" target="_blank">
                    <svg class=" menu-item-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12.5H7.33333C7.68696 12.5 8.02609 12.3683 8.27614 12.1339C8.52619 11.8995 8.66667 11.5815 8.66667 11.25C8.66667 10.9185 8.52619 10.6005 8.27614 10.3661C8.02609 10.1317 7.68696 10 7.33333 10H6V15M15.3333 12.5H17.3333M18 10H15.3333V15M10.6667 10V15H12C12.3536 15 12.6928 14.8683 12.9428 14.6339C13.1929 14.3995 13.3333 14.0815 13.3333 13.75V11.25C13.3333 10.9185 13.1929 10.6005 12.9428 10.3661C12.6928 10.1317 12.3536 10 12 10H10.6667Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 21C19.2091 21 21 19.2091 21 17V12V3H12H7C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span class="menu-item-text">Гайд<span> по сайту</span></span>
                </a>

                <div (click)="handleAuthPopUpToggle()" *ngIf="!userService.isAuthorized" class="menu-item small:!hidden">
                </div>

                <div (click)="handleAuthPopUpToggle()" *ngIf="!userService.isAuthorized" class="menu-item small:!hidden"
                    [ngClass]="{
                    'active': authService.authPopUpState !== EAuthPopUpState.CLOSED
                }">
                    <svg class="menu-item-icon" fill="none" height="24" viewBox="0 0 40 40" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect class="" height="39" rx="19.5" stroke="white" width="39" x="0.5" y="0.5" />
                        <path class=""
                            d="M27 20H18M18 20L21.333 16M18 20L21.333 24M25 15V13.174C25 12.8973 24.9426 12.6237 24.8314 12.3704C24.7203 12.1171 24.5578 11.8896 24.3542 11.7023C24.1507 11.515 23.9104 11.372 23.6488 11.2822C23.3871 11.1925 23.1097 11.158 22.834 11.181L14.834 12.507C14.3341 12.5486 13.868 12.7766 13.5283 13.1458C13.1886 13.5149 13 13.9983 13 14.5V26.007C13 26.5086 13.1886 26.992 13.5283 27.3612C13.868 27.7303 14.3341 27.9583 14.834 28L22.834 28.82C23.1098 28.8429 23.3873 28.8084 23.649 28.7186C23.9107 28.6288 24.151 28.4857 24.3546 28.2983C24.5582 28.1109 24.7206 27.8833 24.8317 27.6298C24.9428 27.3764 25.0001 27.1027 25 26.826V25"
                            stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </svg>
                </div>

                <div *ngIf="userService.isAuthorized" (click)="showProfile=!showProfile"
                    class="menu-item profile-burger" [ngClass]="{
                    'active': showProfile
                }">
                    <svg class="menu-item-icon" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22,17 L22,19 L2,19 L2,17 L22,17 Z M22,11 L22,13 L2,13 L2,11 L22,11 Z M22,5 L22,7 L2,7 L2,5 L22,5 Z"
                            fill-rule="evenodd" />
                    </svg>
                </div>
            </nav>

            <app-user-bar [showProfile]="showProfile" [ngClass]="{'user-menu--opened': showProfile}"
                class="max-tablet:opacity-0 max-tablet:translate-y-10 max-tablet:invisible transition-all" />
        </div>
    </div>
</section>

<app-popup [isOpened]="_subscriptionService.isTariffPopupOpened"
    (closeModalEvent)="_subscriptionService.closeTariffPopup()">
    <div class="light-ellipse">
        <div class="light-ellipse__circle top-0 left-0"></div>
    </div>
    <div class="mb-6 pt-6 pb-8 border-[1px] border-gray2 rounded-bl-none rounded-3xl spliced-block-gradient--double">
        <h2 class="text-center leading-none">Тарифные планы</h2>
        <p class="mt-3 text-body-2 text-center">Выберите свой путь к успеху с нашими тарифными планами!</p>
    </div>
    <app-tariff-list />

    <p class="mt-10 text-body-2 text-center notify">
        Внимание! Нажимая кнопку «Повысить тариф», вы так же подключаете опцию «Ежемесячного автоплатежа». Отключить опцию можно в <a routerLink="/profile">Личном кабинете</a>
    </p>

    <div class="light-ellipse">
        <div class="light-ellipse__circle right-0 bottom-0"></div>
    </div>
</app-popup>