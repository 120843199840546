<app-header />
<main>
    <router-outlet />
    <div class="menu-intersection"></div>
</main>
<app-auth-popup />
<app-main-menu />
<app-footer />
<notifier-container />

<!-- Модальное окно подтверждения действия -->
<app-confirmation-popup />