<footer>
    <div
        class="my-6 mx-auto w-full flex flex-wrap tablet:flex-row phone:flex-col justify-center tablet:gap-6 gap-3 items-center">
        <p class="text-caption">© MSCinsider {{yearNow}}</p>
        <p class="text-caption">ИНН 773421094490</p>
        <p class="text-caption">ОГРН 323774600304958</p>
        <p class="text-caption">ИП Молчанов А.А.</p>
        <a class="text-caption" href="mailto:mscinsider@yandex.com">Тех. поддержка</a>
        <a class="text-caption" [href]="downloadLink" download target="_blank">Политика конфиденциальности</a>
        <a class="text-caption" [href]="agreementLink" download target="_blank">Пользовательское соглашение</a>
        <a class="text-caption" [href]="recurringLink" download target="_blank">Политика рекуррентных платежей</a>
        <a class="text-caption" href="https://t.me/MSCinsider" target="_blank">Telegram</a>
        <span class="flex items-center text-caption">
            <span>Made with</span>
            <svg class="mx-1" fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5.63612C0 9.80812 3.21554 12.0313 5.56938 14.0213C6.4 14.7235 7.2 15.3846 8 15.3846C8.8 15.3846 9.6 14.7235 10.4306 14.0213C12.7845 12.0313 16 9.80812 16 5.63612C16 1.46409 11.5998 -1.49463 8 2.5163C4.40013 -1.49463 0 1.46409 0 5.63612Z" fill="white" />
            </svg>
            <span>by Easy Agency</span>
        </span>
    </div>
</footer>